import React, { useState } from "react";
import axios from "axios";
import { URL } from "../../../link";
import VideoPlayer from "../../Publiaciones/VideoPlayer";
import style from "../ModalMetrica.module.css";

/**
 * Componente para verificar una métrica basada en un video.
 * Permite a los usuarios validar si un video respalda la métrica registrada.
 *
 * @param {Function} filterMetricas - Función para filtrar métricas del usuario.
 * @param {string} metricaV - Nombre de la métrica a verificar.
 * @param {boolean} editBool - Estado que indica si el componente está en modo edición.
 * @param {Function} setEditBool - Función para actualizar el estado de edición.
 * @param {Object} user - Información del usuario con métricas asociadas.
 */
const VerificarMetrica = ({ filterMetricas, metricaV, editBool, setEditBool, user }) => {
  const [califico, setCalifico] = useState(0);
  const [valor, setValor] = useState("");

  /**
   * Maneja la selección de opción "Sí" o "No".
   * @param {Event} event - Evento del input.
   */
  const handlerValor = (event) => {
    setValor(event.target.value);
  };

  /**
   * Envía la verificación de la métrica al servidor.
   * @param {number} id - ID de la métrica a verificar.
   */
  const enviar = async (id) => {
    const data = { id, valor };
    if (califico === 0) {
      try {
        await axios.put(`${URL}/metrica/verificar`, data);
        setEditBool(true);
        setCalifico(1);
      } catch (error) {
        console.error("Error al enviar la verificación de métrica:", error);
      }
    }
  };

  return (
    <div className={style.contEdit}>
      {!editBool && (
        <div className={style.contBtnG}>
          <h2 className={style.titEdit}>VERIFICAR MÉTRICA</h2>
          <p className={style.avisoG}>¿Consideras que el video valida la métrica?</p>

          <div className={style.contEditBtn}>
            <div className={style.contOption}>
              <input
                type="radio"
                className={style.checkboxP}
                id="Si"
                value="Si"
                checked={valor === "Si"}
                onChange={handlerValor}
              />
              <p className={style.txtSi}>Sí</p>
            </div>

            <div className={style.contOption}>
              <input
                type="radio"
                className={style.checkboxP}
                id="No"
                value="No"
                checked={valor === "No"}
                onChange={handlerValor}
              />
              <p className={style.txtNo}>No</p>
            </div>
            
            {valor !== "" && (
              <p
                className={style.txtEnviar}
                onClick={() => enviar(filterMetricas(user, metricaV)[0].id)}
              >
                Enviar
              </p>
            )}
            <p className={style.txtCerrar} onClick={() => setEditBool(true)}>
              Cerrar
            </p>
          </div>

          {/* Reproducción del video asociado a la métrica */}
          <div className={style.contVideo}>
            <VideoPlayer
              width={350}
              height={250}
              video={filterMetricas(user, metricaV)[0].video}
              controls
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default VerificarMetrica;