import styles from "./User.module.css";
import { formatoLegible } from "../funciones.js";
import ModalMetricaComparativo from "../ModalMetrica/ModalMetricaComparativo.jsx";
import ModalPosicionComparativo from "../ModalMetrica/ModalPosicionComparativo.jsx";
import { useState } from "react";
import PosicionVisitor from "./components/PosicionVisitor.jsx";
import HabilidadesVisitor from "./components/HabilidadesVisitor.jsx";
import MetricasVisitor from "./components/MetricasVisitor.jsx";
import Score from "./components/Score.jsx";
import axios from "axios";
import { useEffect } from "react";
import { URL } from "../../link.js";
import ModalScore from "../ModalScore/ModalScore.jsx";

const UserVisitor = ({ user }) => {
  const userLocal = JSON.parse(localStorage.getItem("dataUser"));
  const [tab, setTab] = useState("habilidades");
  const [radarBool, setRadarBool] = useState(false);
  const [posicionBool, setPosicionBool] = useState(false);
  const [modalScore, setModalScore] = useState(false)
  const [dateSelected, setDateSelected] = useState("")

  const posicionesLegibles = user.posicionesSeleccionadas.map(formatoLegible);

  const handleRadar = () => {
    setRadarBool(!radarBool);
  };

  const handlePosicion = () => {
    setPosicionBool(!posicionBool);
  };

  const getScoresByVisitor = async (data) => {
    if (data?.userId) {
      try {
        const response = await axios.post(`${URL}/score/dates`, data);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    }
  };

  const openModalScore = (date) => {
    setModalScore(true)
    setDateSelected(date)
  }

  const getEntrenamientosByVisitor = async (data) => {
    if (data?.userId) {
      try {
        const response = await axios.post(`${URL}/entrenamiento/dates`, data);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    }
  };

  useEffect(() => {
    getScoresByVisitor();
    getEntrenamientosByVisitor();
  }, []);

  return (
    <div className={styles.conteiner}>
      <PosicionVisitor
        user={user}
        handlePosicion={handlePosicion}
        posicionesLegibles={posicionesLegibles}
        userLocal={userLocal}
      />
      <HabilidadesVisitor user={user} />
      <div className={styles.contTitle}>
        <h3 className={styles.seccion}>METRICAS PERSONALES</h3>
      </div>
      <div className={styles.tab}>
        <div
          className={
            tab === "habilidades" ? styles.tabItemActive : styles.tabItem
          }
          onClick={() => setTab("habilidades")}
        >
          <p className={styles.tabText}>HABILIDADES</p>
        </div>
        <div
          className={tab === "bsd" ? styles.tabItemActive : styles.tabItem}
          onClick={() => setTab("bsd")}
        >
          <p className={styles.tabText}>BSD SCORE</p>
        </div>
      </div>
      {tab === "habilidades" ? (
        <MetricasVisitor
          user={user}
          userLocal={userLocal}
          handleRadar={handleRadar}
        />
      ) : (
        <Score
          user={user}
          getScoresByVisitor={getScoresByVisitor}
          getEntrenamientosByVisitor={getEntrenamientosByVisitor}
          isVisitor={true}
          openModalScore={openModalScore}
        />
      )}
      { modalScore && <ModalScore
      user={user}
      setModalScore={setModalScore}
      dateSelected={dateSelected}
      />}
      {radarBool && (
        <ModalMetricaComparativo user={user} handleRadar={handleRadar} />
      )}
      {posicionBool && (
        <ModalPosicionComparativo user={user} handlePosicion={handlePosicion} />
      )}
    </div>
  );
};

export default UserVisitor;
