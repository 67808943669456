import React from "react";
import styles from "../User.module.css";
import posicion from "../../../assets/Campo.png";
import { formatoLegible } from "../../funciones.js";

const PosicionVisitor = ({
  user,
  userLocal,
  handlePosicion,
  posicionesLegibles,
}) => {
  return (
    <div className={styles.posicion}>
      <div className={styles.contTitle}>
        <h3 className={styles.seccion}>POSICIÓN DE JUEGO</h3>
        {userLocal.rol === "Jugador" && (
          <p className={styles.btnTxt} onClick={handlePosicion}>
            COMPARAR
          </p>
        )}
      </div>
      <img className={styles.campo} src={posicion} alt="" />
      <h3 className={styles.title}>POSICIÓN FAVORITA</h3>
      <div className={styles.contPosicion}>
        <div className={styles.posi}>
          <p className={styles.txt}>{formatoLegible(user.posicion)}</p>
        </div>
      </div>
      <h3 className={styles.title}>OTRAS POSICIONES</h3>
      <div className={styles.contPosicion}>
        <div className={styles.posi}>
          <p className={styles.txt}>{posicionesLegibles[0]}</p>
        </div>
        <div className={styles.posi}>
          <p className={styles.txt}>{posicionesLegibles[1]}</p>
        </div>
      </div>
    </div>
  );
};

export default PosicionVisitor;
