import { useEffect, useState } from "react";
import style from "./ModalMetrica.module.css";
import axios from "axios";
import { URL } from "../../link";
import Historico from "./Componentes/Historico";
import GraficaVisitor from "./Componentes/GraficaVisitor";

/**
 * Componente modal para visualizar métricas del usuario.
 * Permite alternar entre vista comparativa e histórica de las métricas.
 * 
 * @param {Function} setMetricaBool - Función para cerrar el modal.
 * @param {string} metricaV - Nombre de la métrica a visualizar.
 * @param {Object} user - Datos del usuario, incluyendo métricas.
 */
const ModalMetricaVisitor = ({ setMetricaBool, metricaV, user }) => {
  // Estado para alternar entre vista comparativa e histórica
  const [comparativoBool, setComparativoBool] = useState(true);
  const [historicoBool, setHistoricoBool] = useState(false);
  
  // Estado para almacenar las seis métricas más recientes del usuario
  const [firstSixPosicion, setFirstSixPosicion] = useState(user.Metricas);
  
  // Estado para almacenar los datos comparativos obtenidos de la API
  const [arrayComparativo, setArrayComparativo] = useState([]);
  
  // Estado para manejar la visibilidad de la sección histórica
  const [bool, setBool] = useState(false);

  /**
   * Activa la vista comparativa de métricas.
   */
  const onClickComparativo = () => {
    setComparativoBool(true);
    setHistoricoBool(false);
    setBool(false);
  };

  /**
   * Activa la vista histórica de métricas.
   */
  const onClickHistorico = () => {
    setComparativoBool(false);
    setHistoricoBool(true);
    setBool(true);
  };

  /**
   * Filtra las métricas del usuario para obtener solo las del tipo seleccionado.
   * @returns {Array} - Lista de métricas filtradas y ordenadas por fecha.
   */
  const filterMetricas = () => {
    return user.Metricas.filter((metric) => metric.nombre === metricaV)
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  };

  /**
   * Obtiene los datos comparativos de la métrica seleccionada desde la API.
   */
  const getComparativo = async () => {
    try {
      const response = await axios.get(`${URL}/metrica/comparativo/${metricaV}`);
      setArrayComparativo(response.data);
    } catch (error) {
      console.error("Error al obtener las métricas:", error.message);
    }
  };

  // Efecto para obtener los datos comparativos y las métricas filtradas al montar el componente
  useEffect(() => {
    if (arrayComparativo.length === 0) {
      getComparativo();
      getFilterMetricas();
    }
  }, []);

  /**
   * Obtiene las métricas del usuario desde la API y filtra las seis más recientes.
   */
  const getFilterMetricas = async () => {
    try {
      const response = await axios.get(`${URL}/metrica/user/${user.id}`);
      const metricasFiltradas = response.data.Metricas.filter(
        (metrica) => metrica.nombre === metricaV
      );
      const metricasOrdenadas = metricasFiltradas
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        .slice(0, 6);
      setFirstSixPosicion(metricasOrdenadas);
      // Se podría incluir un dispatch getUser para actualizar las métricas
    } catch (error) {
      console.error("Error al obtener las métricas:", error.message);
    }
  };

  return (
    <div className={style.conteiner}>
      {/* Botón para cerrar el modal */}
      <p className={style.titleMAnd} onClick={() => setMetricaBool(false)}>
        cerrar
      </p>
      
      {/* Contenedor del modal con vista dinámica según el estado */}
      <div className={!bool ? style.modalContentAnd : style.modalContent}>
        <GraficaVisitor
          user={user}
          metricaV={metricaV}
          comparativoBool={comparativoBool}
          firstSixPosicion={firstSixPosicion}
          getComparativo={getComparativo}
          getFilterMetricas={getFilterMetricas}
        />
        <Historico
          user={user}
          metricaV={metricaV}
          bool={bool}
          comparativoBool={comparativoBool}
          arrayComparativo={arrayComparativo}
          filterMetricas={filterMetricas}
          onClickComparativo={onClickComparativo}
          onClickHistorico={onClickHistorico}
          historicoBool={historicoBool}
          setMetricaBool={setMetricaBool}
        />
      </div>
    </div>
  );
};

export default ModalMetricaVisitor;
