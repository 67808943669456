import { useEffect, useState } from 'react';
import style from "./Seleccion.module.css";
import { useNavigate } from 'react-router-dom';
import logo from "../../assets/Logo.svg";
import axios from 'axios';
import { mesesOptions, estadosOptions } from '../funciones';
import municipiosByEstado from "../municipios.json";
import ModalImage from '../ModalImagen/ModalImage';

// Configuración de constantes
const CLOUDINARY_CONFIG = {
  preset_key: "bqgn8ppn",
  cloud_name: "dvwtgluwc",
  url: "https://api.cloudinary.com/v1_1/dvwtgluwc/image/upload"
};

const PIE_OPTIONS = [
  { id: "Derecho", label: "Derecho" },
  { id: "Izquierdo", label: "Izquierdo" },
  { id: "Ambos", label: "Ambos" }
];

// Componentes reutilizables
const SelectField = ({ label, value, onChange, options, name, className }) => (
  <div className={style.camp}>
    <p className={style.descripcionCamp}>{label}</p>
    <div className={style.contInp}>
      <select 
        name={name}
        className={className || style.inputCamp}
        value={value}
        onChange={onChange}
      >
        {options}
      </select>
    </div>
  </div>
);

const InputField = ({ label, value, onChange, type = "text", unit, name }) => (
  <div className={style.camp}>
    <p className={style.descripcionCamp}>{label}</p>
    <div className={style.contInp}>
      <input
        type={type}
        name={name}
        className={style.inputCamp}
        value={value}
        onChange={onChange}
      />
      {unit && <span className={style.medida}>{unit}</span>}
    </div>
  </div>
);

export default function Basica() {
    const navigate = useNavigate();
    const usuario = JSON.parse(localStorage.getItem("dataUser"));

    // Estados principales
    const [formData, setFormData] = useState({
        dia: "",
        mes: "",
        anio: "",
        altura: "",
        peso: "",
        origen: "",
        direccion: "selecciona una opción",
        pie: "",
        equipo: "",
        foto: "",
        urlNewFoto: ""
    });

    // Estados auxiliares
    const [aceptaTerminos, setAceptaTerminos] = useState(false);
    const [aviso, setAviso] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [municipios, setMunicipios] = useState([]);

    // Nuevo estado para controlar si es menor de edad
    const [isMenorDeEdad, setIsMenorDeEdad] = useState(false);

    // Manejadores de cambios
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
        setAviso("");

        // Si el cambio es en algún campo de la fecha, calcular la edad
        if (name === 'dia' || name === 'mes' || name === 'anio') {
            const nuevaFecha = {
                dia: name === 'dia' ? value : formData.dia,
                mes: name === 'mes' ? value : formData.mes,
                anio: name === 'anio' ? value : formData.anio
            };

            const edad = calcularEdad(nuevaFecha.dia, nuevaFecha.mes, nuevaFecha.anio);
            
            if (edad !== null) {
                setIsMenorDeEdad(edad < 18);
                // Si es mayor de edad, asegurar que aceptaTerminos sea true
                if (edad >= 18) {
                    setAceptaTerminos(true);
                }
            }
        }
    };

    const handlePieChange = (e) => {
        setFormData(prev => ({
            ...prev,
            pie: e.target.value
        }));
        setAviso("");
    };

    const handleAceptaTerminosChange = (e) => {
        setAceptaTerminos(e.target.checked);
        setAviso("");
    };

    // Función para calcular la edad
    const calcularEdad = (dia, mes, anio) => {
        if (!dia || !mes || !anio) return null;
        
        const fechaNacimiento = new Date(anio, mes - 1, dia);
        const hoy = new Date();
        let edad = hoy.getFullYear() - fechaNacimiento.getFullYear();
        const mesActual = hoy.getMonth();
        const diaActual = hoy.getDate();
        const mesNacimiento = fechaNacimiento.getMonth();
        const diaNacimiento = fechaNacimiento.getDate();

        if (mesActual < mesNacimiento || 
            (mesActual === mesNacimiento && diaActual < diaNacimiento)) {
            edad--;
        }

        return edad;
    };

    // Validaciones
    const validarFormulario = () => {
        const errores = [];
        
        if (!formData.altura || isNaN(formData.altura)) 
            errores.push("Altura inválida");
        if (!formData.peso || isNaN(formData.peso)) 
            errores.push("Peso inválido");
        if (!formData.dia || !formData.mes || !formData.anio) 
            errores.push("Fecha de nacimiento incompleta");
        if (!formData.origen) 
            errores.push("Selecciona un estado");
        if (!formData.direccion || formData.direccion === "selecciona una opción") 
            errores.push("Selecciona una ciudad");
        if (!formData.pie) 
            errores.push("Selecciona tu pie favorito");
        if (!aceptaTerminos) 
            errores.push("Debes aceptar los términos y condiciones");

        // Solo validar términos si es menor de edad
        if (isMenorDeEdad && !aceptaTerminos) {
            errores.push("Debes aceptar los términos y condiciones");
        }

        return errores;
    };

    // Navegación
    const continuar = () => {
        const errores = validarFormulario();
        if (errores.length > 0) {
            setAviso(errores.join(", "));
            return;
        }
        
        const usuarioActualizado = {
            ...usuario,
            ...formData
        };
        
        localStorage.setItem("dataUser", JSON.stringify(usuarioActualizado));
        navigate("/posicion");
    };

    const regresar = () => navigate("/seleccion");

    // Efectos
    useEffect(() => {
        if (formData.origen) {
            const municipiosDelEstado = municipiosByEstado[formData.origen] || [];
            const opcionesMunicipios = municipiosDelEstado.map(muni => (
                <option key={muni} value={muni}>{muni}</option>
            ));
            setMunicipios(opcionesMunicipios);
            setFormData(prev => ({
                ...prev,
                direccion: "selecciona una opción"
            }));
        }
    }, [formData.origen]);

    // Opciones para selects
    const diasOptions = Array.from({ length: 31 }, (_, i) => (
        <option key={i + 1} value={i + 1}>{i + 1}</option>
    ));

    const currentYear = new Date().getFullYear();
    const yearsOptions = Array.from(
        { length: currentYear - 1970 + 1 }, 
        (_, i) => <option key={currentYear - i} value={currentYear - i}>{currentYear - i}</option>
    );

    return (
        <div className={style.container}>
            <div className={style.general}>
                <div className={style.contLogo}>
                    <img className={style.logo} src={logo} alt="Big Sport Data" />
                </div>
                <div className={style.basica}>
                    <div className={style.inter}>
                        <h1 className={style.regName}>INFORMACIÓN BÁSICA</h1>
                        <div className={style.conText}>
                            <span className={style.linkText}>
                                Estas muy cerca de poder acceder a la plataforma, únicamente requerimos un poco de información adicional sobre tus atributos y cualidades.
                            </span>
                        </div>

                        {/* Sección de foto */}
                        <div className={style.contFoto}>
                            <p className={style.descripcion}>Agrega tu fotografía o un avatar que te represente</p>
                            <div className={style.contSubida}>
                                {formData.urlNewFoto && 
                                    <img className={style.fotoPerfil} src={formData.urlNewFoto} alt="Perfil" />
                                }
                                <button
                                    className={style.uploadButton}
                                    onClick={() => setIsModalOpen(true)}
                                >
                                    Subir foto
                                </button>
                            </div>
                        </div>

                        {/* Fecha de nacimiento */}
                        <div className={style.nacimiento}>
                            <p className={style.descripcion}>Fecha de nacimiento</p>
                            <div className={style.contSelect}>
                                <select 
                                    className={style.selector} 
                                    name="dia"
                                    value={formData.dia}
                                    onChange={handleInputChange}
                                >
                                    {diasOptions}
                                </select>
                                <select 
                                    className={style.selectorM}
                                    name="mes"
                                    value={formData.mes}
                                    onChange={handleInputChange}
                                >
                                    {mesesOptions}
                                </select>
                                <select 
                                    className={style.selector}
                                    name="anio"
                                    value={formData.anio}
                                    onChange={handleInputChange}
                                >
                                    {yearsOptions}
                                </select>
                            </div>
                        </div>

                        {/* Pie favorito */}
                        <div className={style.contPie}>
                            <p className={style.descripcion}>Pie favorito</p>
                            <div className={style.contOpcionesPie}>
                                {PIE_OPTIONS.map(({ id, label }) => (
                                    <div key={id} className={style.opt}>
                                        <input
                                            type="radio"
                                            className={style.checkboxP}
                                            id={id}
                                            value={id}
                                            checked={formData.pie === id}
                                            onChange={handlePieChange}
                                        />
                                        <label className={style.labelP} htmlFor={id}>{label}</label>
                                    </div>
                                ))}
                            </div>
                        </div>

                        {/* Medidas */}
                        <div className={style.cont}>
                            <InputField
                                label="Altura"
                                name="altura"
                                value={formData.altura}
                                onChange={handleInputChange}
                                unit="cm"
                            />
                            <InputField
                                label="Peso"
                                name="peso"
                                value={formData.peso}
                                onChange={handleInputChange}
                                unit="kg"
                            />
                        </div>

                        {/* Ubicación */}
                        <div className={style.cont}>
                            <SelectField
                                label="Estado"
                                name="origen"
                                value={formData.origen}
                                onChange={handleInputChange}
                                options={estadosOptions}
                            />
                            <SelectField
                                label="Ciudad"
                                name="direccion"
                                value={formData.direccion}
                                onChange={handleInputChange}
                                options={municipios}
                            />
                        </div>

                        {/* Términos y condiciones (solo para menores) */}
                        {isMenorDeEdad && (
                            <div className={style.contCheckb}>
                                <input
                                    type="checkbox"
                                    className={style.checkbox}
                                    id="aceptaTerminos"
                                    checked={aceptaTerminos}
                                    onChange={handleAceptaTerminosChange}
                                />
                                <label htmlFor="aceptaTerminos" className={style.labelCheckbox}>
                                    <span className={style.linkTextCheck}>
                                        Confirmó que tengo el permiso de mis padres para utilizar esta plataforma bajo los
                                    </span>
                                    <span className={style.linkedCheck}>
                                        términos y condiciones de uso de menores de edad.
                                    </span>
                                </label>
                            </div>
                        )}

                        {/* Equipo actual */}
                        <div className={style.conto}>
                            <InputField
                                label="Equipo actual"
                                name="equipo"
                                value={formData.equipo}
                                onChange={handleInputChange}
                            />
                        </div>

                        <span className={style.avisoB}>{aviso}</span>

                        {/* Botones de navegación */}
                        <div className={style.contB}>
                            <button
                                className={style.btnRegresar}
                                onClick={regresar}
                            >
                                Regresar
                            </button>
                            <button
                                className={style.btnContinuar}
                                onClick={continuar}
                                disabled={isMenorDeEdad && !aceptaTerminos}
                            >
                                Continuar
                            </button>
                        </div>
                    </div>
                </div>
                <p className={style.copyright}>© 2024. Big Sport Data Todos los derechos reservados.</p>
            </div>
            {isModalOpen && 
                <ModalImage 
                    setIsModalOpen={setIsModalOpen} 
                    user={usuario} 
                    setUrlNewFoto={(url) => setFormData(prev => ({ ...prev, urlNewFoto: url }))}
                />
            }
        </div>
    );
}
