export const obtenerGolesDelMes = (entrenamientos, dateOrRange, setGolesMes) => {
  const golesDelMes = entrenamientos
    .filter((ent) => {
      const fechaEntrenamiento = new Date(ent.createdAt);

      if (dateOrRange.inicio && dateOrRange.fin) {
        // Si es un rango de fechas
        return fechaEntrenamiento >= dateOrRange.inicio && 
               fechaEntrenamiento <= dateOrRange.fin &&
               ent.goles !== null;
      } else {
        // Si es una fecha única
        return fechaEntrenamiento.getFullYear() === dateOrRange.getFullYear() &&
               fechaEntrenamiento.getMonth() === dateOrRange.getMonth() &&
               ent.goles !== null;
      }
    })
    .reduce((total, ent) => total + ent.goles, 0);

  setGolesMes(golesDelMes);
};