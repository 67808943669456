import React, { useState, useMemo } from "react";
import { Line, Bar } from "react-chartjs-2";
import style from "../ModalMetrica.module.css";
import iconFuerza from "../../../assets/iconFuerza.png";
import { getChartOptions, getUnidad } from "../utils/getChartOptions";
import { arrayFechas, arrayValores, fechaFormateada } from "../utils/metricsUtils";
import { filterMetricas } from "../utils/filterMetricas";
import SubirMetrica from "./SubirMetrica";

/**
 * Componente que muestra una gráfica de métricas del usuario.
 * Permite visualizar datos históricos y subir nuevas métricas.
 *
 * @param {Object} props - Propiedades del componente.
 * @param {Object} props.user - Datos del usuario actual.
 * @param {string} props.metricaV - Nombre de la métrica a visualizar.
 * @param {boolean} props.comparativoBool - Indica si se está en modo comparativo.
 * @param {Array} props.firstSixPosicion - Últimos seis datos de la métrica.
 * @param {Function} props.getComparativo - Función para obtener datos comparativos.
 * @param {Function} props.getFilterMetricas - Función para obtener métricas filtradas.
 */
const Grafica = ({
  user,
  metricaV,
  comparativoBool,
  firstSixPosicion,
  getComparativo,
  getFilterMetricas,
}) => {
  const [editBool, setEditBool] = useState(true);

  // Filtra las métricas del usuario según la métrica seleccionada
  const filteredData = useMemo(() => filterMetricas(user, metricaV), [user, metricaV]);
  
  // Extrae los datos actuales desde los datos filtrados
  const currentData = useMemo(() => ({
    valor: filteredData[0]?.valor || 0,
    createdAt: filteredData[0]?.createdAt,
    verificaPositivo: filteredData[0]?.verificaPositivo || 0,
    verificaNegativo: filteredData[0]?.verificaNegativo || 0
  }), [filteredData]);

  // Configuración del gráfico con los datos correspondientes
  const chartConfig = useMemo(() => ({
    labels: comparativoBool 
      ? arrayFechas(firstSixPosicion) || ["sin valores"]
      : arrayFechas(filteredData) || ["sin valores"],
    datasets: [{
      label: "",
      data: comparativoBool 
        ? arrayValores(firstSixPosicion) || [0]
        : arrayValores(filteredData) || [0],
      fill: false,
      borderColor: "rgba(228, 253, 63, 1)",
      backgroundColor: "rgba(228, 253, 63, 1)",
      borderWidth: 3,
      tension: 0.5,
    }]
  }), [comparativoBool, firstSixPosicion, filteredData]);

  // Opciones de configuración del gráfico
  const chartOptions = useMemo(() => ({
    ...getChartOptions(metricaV),
    maintainAspectRatio: false,
    responsive: true,
    animation: false,
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          color: 'rgba(21, 26, 32, 1)',
        },
        ticks: {
          color: 'white',
          font: {
            family: 'Raleway',
          }
        }
      },
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: 'white',
          font: {
            family: 'Raleway',
          }
        }
      }
    },
    plugins: {
      legend: {
        display: false
      }
    }
  }), [metricaV]);

  // Renderiza el gráfico en función del tipo de métrica
  const ChartComponent = ({ type }) => {
    const ChartType = type === "line" ? Line : Bar;
    return (
      <div className={style.chartContainer}>
        <ChartType data={chartConfig} options={chartOptions} />
      </div>
    );
  };

  return (
    <div className={style.contGrafica}>
      <div className={style.contTitle}>
        <img className={style.iconFuerza} src={iconFuerza} alt="Ícono de fuerza" />
        <h2 className={style.title}>{metricaV.toUpperCase()}</h2>
      </div>

      <div className={style.contInfo}>
        <div className={style.infoC}>
          <h4 className={style.sub}>ACTUAL</h4>
          <div className={style.contData}>
            <h1 className={style.data}>{currentData.valor}</h1>
            <h3 className={style.unidad}>{getUnidad(metricaV)}</h3>
          </div>
        </div>

        <div className={style.contFecha}>
          <div className={style.contSubfecha}>
            <p className={style.subFecha}>Última edición</p>
            <p className={style.fecha}>{currentData.createdAt && fechaFormateada(currentData.createdAt)}</p>
            <p className={style.fecha}>Veces validada: {currentData.verificaPositivo + currentData.verificaNegativo}</p>
          </div>

          {!editBool ? (
            <SubirMetrica
              user={user}
              metricaV={metricaV}
              getComparativo={getComparativo}
              getFilterMetricas={getFilterMetricas}
              editBool={editBool}
              setEditBool={setEditBool}
            />
          ) : (
            <button className={style.btnG} onClick={() => setEditBool(false)}>Subir nueva</button>
          )}
        </div>
      </div>

      {editBool && (
        <div className={style.conSubGrafico}>
          <h4 className={style.sub}>HISTÓRICO</h4>
          <ChartComponent type={metricaV === "fuerza" || metricaV === "resistencia" ? "line" : "bar"} />
        </div>
      )}
    </div>
  );
};

export default React.memo(Grafica);
