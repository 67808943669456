import { useState } from 'react';
import axios from 'axios';

export const useVideoUpload = (preset_key, cloud_name) => {
  const [loadingVideo, setLoadingVideo] = useState(false);
  const [videoLoadingInProgress, setVideoLoadingInProgress] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [upLoadVideo, setUpLoadVideo] = useState("");

  const uploadVideo = async (file) => {
    if (!file?.type.startsWith("video/")) {
      console.log("El archivo no es un video");
      return;
    }

    setVideoLoadingInProgress(true);
    setLoadingVideo(true);
    setUploadProgress(0);

    try {
      const video = document.createElement("video");
      video.preload = "metadata";

      video.onloadedmetadata = async function () {
        window.URL.revokeObjectURL(video.src);
        const duration = video.duration;

        if (duration <= 1200) {
          const formData = new FormData();
          formData.append("file", file);
          formData.append("upload_preset", preset_key);

          try {
            const response = await axios.post(
              `https://api.cloudinary.com/v1_1/${cloud_name}/video/upload`, 
              formData,
              {
                onUploadProgress: (progressEvent) => {
                  const progress = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                  );
                  setUploadProgress(progress);
                  setVideoLoadingInProgress(true);
                }
              }
            );
            setUpLoadVideo(response.data.public_id);
            setVideoLoadingInProgress(false);
            setLoadingVideo(false);
          } catch (error) {
            console.error("Error al subir el video:", error);
            setVideoLoadingInProgress(false);
            setLoadingVideo(false);
          }
        } else {
          console.log("El video supera los 35 segundos");
          setVideoLoadingInProgress(false);
          setLoadingVideo(false);
        }
      };

      video.src = window.URL.createObjectURL(file);
    } catch (error) {
      console.error("Error al procesar el video:", error);
      setVideoLoadingInProgress(false);
      setLoadingVideo(false);
    }
  };

  const resetVideo = () => {
    setUpLoadVideo("");
    setLoadingVideo(false);
    setVideoLoadingInProgress(false);
    setUploadProgress(0);
  };

  return {
    loadingVideo,
    videoLoadingInProgress,
    upLoadVideo,
    uploadVideo,
    resetVideo,
    setUpLoadVideo,
    uploadProgress
  };
}; 