import React from "react";
import styles from "../Stadistics.module.css"
import notIcont from "../../../assets/x21.png"
import ok from "../../../assets/ok.png"
import balonImage from "../../../assets/balonMetrica.svg"

// Componente que muestra el calendario de entrenamientos y racha actual
const Equipo = ({
  entrenamientos,              // Lista de entrenamientos del usuario
  currentDate,                 // Fecha actual
  rachaActual,                // Número de días consecutivos de entrenamiento
  handlerClickEntrenamiento   // Manejador para ver todos los entrenamientos
}) => {
  // Obtiene el día de la semana para el índice dado
  const getDayOfWeek = (index) => {
    const today = new Date();
    const startOfWeek = new Date(
      today.setDate(today.getDate() - today.getDay())
    );
    return new Date(startOfWeek.setDate(startOfWeek.getDate() + index));
  };

  // Verifica si existe un entrenamiento para una fecha específica
  const hasEntrenamiento = (date) => {
    const normalizedDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );

    return entrenamientos.find((ent) => {
      const entDate = new Date(ent.createdAt);
      const normalizedEntDate = new Date(
        entDate.getFullYear(),
        entDate.getMonth(),
        entDate.getDate()
      );
      return normalizedEntDate.getTime() === normalizedDate.getTime();
    });
  };

  return (
    <div>
      {/* Encabezado con fecha y botón */}
      <div className={styles.contMetriBtn}>
        <h2 className={styles.seccionName}>
          {currentDate.toLocaleDateString("es-ES", {
            year: "numeric",
            month: "long",
          })}
        </h2>
        <p className={styles.txtBtn} onClick={handlerClickEntrenamiento}>
         VER TODO
        </p>
      </div>

      {/* Días de la semana */}
      <div className={styles.semana}>
        {["D", "L", "M", "M", "J", "V", "S"].map((dia, index) => (
          <div key={index} className={styles.diaSemana}>
            {dia}
          </div>
        ))}
      </div>

      {/* Calendario de entrenamientos */}
      <div className={styles.contMetricaE}>
        {Array.from({ length: 7 }, (_, index) => {
          const dayOfWeek = getDayOfWeek(index);
          const normalizedDayOfWeek = new Date(
            dayOfWeek.getFullYear(),
            dayOfWeek.getMonth(),
            dayOfWeek.getDate()
          );

          const entrenamiento = hasEntrenamiento(normalizedDayOfWeek);
          const value = entrenamiento ? entrenamiento.tiempo : 0;
          const tipo = entrenamiento ? entrenamiento.tipo : "";

          return (
            <div key={index} className={styles.metricaE}>
              <h4 className={styles.metricaNameE}>
                {normalizedDayOfWeek.getDate()}
              </h4>
              {value === 0 ? (
                <img src={notIcont} alt="" className={styles.notIcon} />
              ) : (
                <div className={styles.contCirculo}>
                  <h3 className={styles.numE}>{value}"</h3>
                  {tipo === "Partido" && (
                    <img
                      src={balonImage}
                      alt="balon"
                      className={styles.balon}
                    />
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>

      {/* Contador de racha */}
      <div className={styles.contPartidos}>
        <h4 className={styles.txtP}>RACHA ACTUAL</h4>
        <div className={styles.partidos}>
          <img className={styles.icon1} src={ok} alt="" />
          <h4 className={styles.daysText}>{rachaActual} días</h4>
        </div>
      </div>
    </div>
  );
};

export default Equipo;
