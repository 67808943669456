export function generaDataLinea(rangoFechas, valor) {
    const data = [];
    const fechaInicio = new Date(rangoFechas.inicio);
    const fechaFin = new Date(rangoFechas.fin);
    
    // Calcular la diferencia en días
    const dias = Math.floor((fechaFin - fechaInicio) / (1000 * 60 * 60 * 24));
  
    for (let i = 0; i <= dias; i++) {
      const fecha = new Date(fechaInicio);
      fecha.setDate(fechaInicio.getDate() + i);
      
      data.push({
        fecha: fecha.toISOString(),
        valor
      });
    }
  
    return data;
  }