export  const getRachaActual = (entrenamientos, setRachaActual) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);


    const sortedEntrenamientos = entrenamientos
      .map((ent) => ({
        ...ent,
        normalizedDate: new Date(new Date(ent.createdAt).setHours(0, 0, 0, 0)),
      }))
      .sort((a, b) => b.normalizedDate - a.normalizedDate);

    let streak = 0;
    let currentDate = today;

    for (const ent of sortedEntrenamientos) {
      if (ent.normalizedDate.getTime() === currentDate.getTime()) {
        streak++;
        currentDate.setDate(currentDate.getDate() - 1);
      } else if (ent.normalizedDate < currentDate) {
        break;
      }
    }
    setRachaActual(streak);
  }