export const getTiempoDia = (arrayEntrenamientos, dateOrRange, setTiempoDia) => {
  if (dateOrRange.inicio && dateOrRange.fin) {
    // Modo rango
    const tiempoTotal = arrayEntrenamientos.reduce((total, ent) => {
      const entDate = new Date(ent.createdAt);
      if (entDate >= dateOrRange.inicio && entDate <= dateOrRange.fin) {
        return total + ent.tiempo;
      }
      return total;
    }, 0);
    setTiempoDia(tiempoTotal);
  } else {
    // Modo día único
    const entrenamiento = arrayEntrenamientos.find(
      (ent) => new Date(ent.createdAt).toDateString() === dateOrRange.toDateString()
    );
    setTiempoDia(entrenamiento ? entrenamiento.tiempo : 0);
  }
};