export const getTiempoMesEntrenamiento = (arrayEntrenamientos, dateOrRange, setTiempoMesEntrenamiento) => {
  const entrenamientos = arrayEntrenamientos.filter((ent) => {
    const date = new Date(ent.createdAt);

    if (dateOrRange.inicio && dateOrRange.fin) {
      // Si es un rango de fechas
      return date >= dateOrRange.inicio && 
             date <= dateOrRange.fin && 
             ent.tipo === "Entrenamiento";
    } else {
      // Si es una fecha única
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      const selectedMonth = dateOrRange.getMonth() + 1;
      const selectedYear = dateOrRange.getFullYear();

      return month === selectedMonth &&
             year === selectedYear &&
             ent.tipo === "Entrenamiento";
    }
  });

  const tiempoTotal = entrenamientos.reduce(
    (acc, ent) => acc + ent.tiempo,
    0
  );
  setTiempoMesEntrenamiento(tiempoTotal);
};