import { format } from "date-fns";
import { es } from "date-fns/locale"; // Importar el locale español
import style from "./ModalScores.module.css";
import { Bar } from "react-chartjs-2";
import { useState } from "react";
import axios from "axios";
import { URL } from "../../link";
import { useEffect } from "react";

const ModalScore = ({ user, setModalScore, dateSelected }) => {
  const userLocal = JSON.parse(localStorage.getItem("dataUser"));
  const [scoreMetricas, setScoreMetricas] = useState({});
  const [fechaScoreUpdate, setFechaScoreUpdate] = useState("");
  const [sinScore, setSinScore] = useState(true);
  const [diasSinScore, setDiasSinScore] = useState(0);
  const [fechaHoy, setFechaHoy] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isMounted, setIsMounted] = useState(true);
  const [comentario, setComentario] = useState("");

  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);

  const prepararDatosGrafico = (scoreTotal) => {
    return {
      labels: ["BSD Score"],
      datasets: [
        {
          label: "Entrenamiento",
          data: [scoreTotal * 0.25], // 25%
          backgroundColor: "rgba(76, 60, 207, 1)",
          borderColor: "rgba(76, 60, 207, 1)",
          borderWidth: 1,
        },
        {
          label: "Constancia",
          data: [scoreTotal * 0.1], // 10%
          backgroundColor: "rgba(255, 193, 7, 1)",
          borderColor: "rgba(255, 193, 7, 1)",
          borderWidth: 1,
        },
        {
          label: "Goles",
          data: [scoreTotal * 0.05], // 5%
          backgroundColor: "rgba(40, 167, 69, 1)",
          borderColor: "rgba(40, 167, 69, 1)",
          borderWidth: 1,
        },
        {
          label: "Evaluación Personal",
          data: [scoreTotal * 0.2], // 20%
          backgroundColor: "rgba(220, 53, 69, 1)",
          borderColor: "rgba(220, 53, 69, 1)",
          borderWidth: 1,
        },
        {
          label: "Velocidad",
          data: [scoreTotal * 0.1], // 10%
          backgroundColor: "rgba(23, 162, 184, 1)",
          borderColor: "rgba(23, 162, 184, 1)",
          borderWidth: 1,
        },
        {
          label: "Resistencia",
          data: [scoreTotal * 0.1], // 10%
          backgroundColor: "rgba(111, 66, 193, 1)",
          borderColor: "rgba(111, 66, 193, 1)",
          borderWidth: 1,
        },
        {
          label: "Salto",
          data: [scoreTotal * 0.1], // 10%
          backgroundColor: "rgba(253, 126, 20, 1)",
          borderColor: "rgba(253, 126, 20, 1)",
          borderWidth: 1,
        },
        {
          label: "Fuerza",
          data: [scoreTotal * 0.1], // 10%
          backgroundColor: "rgba(32, 201, 151, 1)",
          borderColor: "rgba(32, 201, 151, 1)",
          borderWidth: 1,
        },
      ],
    };
  };

  const opcionesGrafico = {
    indexAxis: "x", // Cambiado a 'x' para orientación vertical
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        beginAtZero: true,
        max: 100,
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          boxWidth: 12,
          padding: 10,
          font: {
            size: 11, // Reducido el tamaño de la fuente para mejor ajuste
          },
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return `${context.dataset.label}: ${context.raw.toFixed(1)}%`;
          },
        },
      },
    },
    maintainAspectRatio: false,
    responsive: true,
  };

  const transformarFecha = (fechaString) => {
    try {
      const fechaLimpia = fechaString.replace("Fecha: ", "");
      const [dia, mes, año] = fechaLimpia.split("/");

      // Crear el string de fecha en formato ISO
      const fechaISO = `20${año}-${mes.padStart(2, "0")}-${dia.padStart(
        2,
        "0"
      )}T12:00:00`;

      // Crear la fecha usando el string ISO
      const fecha = new Date(fechaISO);

      return fecha;
    } catch (error) {
      console.error("Error al transformar la fecha:", error);
      return new Date();
    }
  };

  const getScoreMetricas = async () => {
    setIsLoading(true);
    const data = {
      userId: user.id,
      fecha: format(fechaHoy, "yyyy-MM-dd"),
    };
    try {
      const response = await axios.post(`${URL}/score/metrics`, data);
      if (isMounted) {
        setScoreMetricas(response.data);
      }
    } catch (error) {
      if (isMounted) {
        setScoreMetricas({})
        console.error("Error al obtener métricas:", error);
      }
    } finally {
      if (isMounted) {
        setIsLoading(false);
      }
    }
  };

  const fechaHoyFormateada = fechaHoy
    ? format(fechaHoy, "EEEE, dd 'de' MMMM 'de' yyyy", {
        locale: es,
      })
    : "";

  const fechaFormateadaCapitalizada = fechaHoyFormateada
    ? fechaHoyFormateada.charAt(0).toUpperCase() + fechaHoyFormateada.slice(1)
    : "";

    useEffect(() => {
        if(dateSelected){
          const hoy = transformarFecha(dateSelected);
          if (isMounted) {
            setFechaHoy(hoy);
          }
        } else {
          const hoy = new Date();
          if (isMounted) {
            setFechaHoy(hoy);
          }
        }
        return () => {
          // Cleanup function
          if (isMounted) {
            setFechaHoy("");
          }
        };
      }, [dateSelected]);

  useEffect(() => {
    if (fechaHoy) {
      getScoreMetricas();
    }
    return () => {
      // Cleanup function
      setScoreMetricas({});
      setIsLoading(true);
    };
  }, [fechaHoy]);

  const getDiasSinScore = () => {
    // Fecha por defecto: 1 de enero de 2025
    const fechaDefault = new Date('2025-01-01');
    
    // Obtener la fecha de actualización del score, si existe
    const fechaUpdate = user.scores?.[0]?.updatedAt 
      ? new Date(user.scores[0].updatedAt)
      : fechaDefault;
      
    const fechaHoy = new Date();
    const diferencia = fechaHoy - fechaUpdate;
    const dias = Math.floor(diferencia / (1000 * 60 * 60 * 24));
    setDiasSinScore(dias);
  };
  
  useEffect(() => {
    if (user.scores?.[0]?.updatedAt) {
      const fechaUpdate = new Date(user.scores[0].updatedAt);
      const fechaFormateada = format(
        fechaUpdate,
        "EEEE, dd 'de' MMMM 'de' yyyy",
        { locale: es }
      );
      setFechaScoreUpdate(
        fechaFormateada.charAt(0).toUpperCase() + fechaFormateada.slice(1)
      );
      setSinScore(false);
    } else {
      setSinScore(true);
      setFechaScoreUpdate(format(
        new Date('2025-01-01'),
        "EEEE, dd 'de' MMMM 'de' yyyy",
        { locale: es }
      ));
    }
    getDiasSinScore();
  }, [user]);

  const calcularPorcentaje = (dias) => {
    const porcentaje = (dias * 0.3).toFixed(1);
    return porcentaje;
  };

  const agregarComentario = async () => {
    const data = {
      id: scoreMetricas.score.id,
      comentario: comentario,
    };
    console.log("agregar comentario", data);
    try {
      const response = await axios.post(`${URL}/score/addComentario`, data);
      await getScoreMetricas();
    } catch (error) {
      console.error("Error al agregar comentario:", error);
    }
  };

  useEffect(() => {
    if (user.scores[0]?.updatedAt) {
      const fechaUpdate = new Date(user.scores[0].updatedAt);
      const fechaFormateada = format(
        fechaUpdate,
        "EEEE, dd 'de' MMMM 'de' yyyy",
        { locale: es }
      );
      setFechaScoreUpdate(
        fechaFormateada.charAt(0).toUpperCase() + fechaFormateada.slice(1)
      );
      setSinScore(false);
    } else {
      setSinScore(true);
    }
    getDiasSinScore();
  }, [user]);

  return (
    <div className={style.conteiner}>
      <div className={style.modalContent}>
        {isLoading ? (
          <div className={style.loading}>Cargando...</div>
        ) : (
            <div className={style.conteiner}>
            <p className={style.titleMAnd}>cerrar</p>
            <div className={style.modalContent}>
              <div className={style.conSubGrafico}>
                <div className={style.contTitle}>
                  <h2 className={style.title}>BSD SCORE</h2>
                </div>
                <Bar
                  data={prepararDatosGrafico(user.scores[0]?.valor || 0)}
                  options={opcionesGrafico}
                  height={200}
                />
              </div>
              <div className={style.contHistorico}>
                <p className={style.txtCerrar} onClick={() => setModalScore(false)}>
                  X
                </p>
                <div className={style.contSubHistorico}>
                  <p className={style.txtFecha}>{fechaFormateadaCapitalizada}</p>
                  <div className={style.contAnalisis}>
                    <p className={style.txtAnalisis}>Analisis BSD</p>
                    <p className={style.txtAnalisis}>SCORE: {user.scores?.[0]?.valor ?? 0}</p>
                  </div>
                  <div className={style.contResumen}>
                    <p className={style.txtResumen}>
                      Score actualizado el {fechaScoreUpdate}.
                    </p>
                    {diasSinScore > 0 && (
                      <p className={style.txtResumen}>
                        {diasSinScore} días sin actividad registrada. Constancia
                        disminuida en un {calcularPorcentaje(diasSinScore)}%
                      </p>
                    )}
                    <div className={style.contEntrenamiento}>
                      {scoreMetricas.entrenamiento === null ? (
                        <p className={style.txtResumen}>
                          No hay entrenamientos registrados de este dia
                        </p>
                      ) : (
                        <p className={style.txtResumen}>
                          Registro {scoreMetricas.entrenamiento?.tipo} de{" "}
                          {scoreMetricas.entrenamiento?.tiempo} minutos,{" "}
                          {scoreMetricas.entrenamiento?.tipo === "Partido" &&
                            scoreMetricas.entrenamiento?.goles > 0 &&
                            "y ha registrado " +
                              scoreMetricas.entrenamiento?.goles +
                              " goles"}
                          {scoreMetricas.entrenamiento?.video && (
                            <p>y se subio un video mostrar video</p>
                          )}
                        </p>
                      )}
                    </div>
                    <div className={style.contMetricas}>
                      {scoreMetricas.metricas !== null ? (
                        <div className={style.metricas}>
                          {scoreMetricas.metricas?.velocidad ? (
                            <p className={style.txtResumen}>
                              {" "}
                              Se regitro la metrica{" "}
                              {scoreMetricas.metricas?.velocidad.nombre}: Recorre 50
                              metros en {scoreMetricas.metricas?.velocidad.valor}{" "}
                              segundos.
                            </p>
                          ) : (
                            <p className={style.txtResumen}>
                              {" "}
                              No hay metrica de velocidad registrada para este dia
                            </p>
                          )}
                          {scoreMetricas.metricas?.fuerza ? (
                            <p className={style.txtResumen}>
                              {" "}
                              Se regitro la metrica{" "}
                              {scoreMetricas.metricas?.fuerza.nombre}: Levanta{" "}
                              {scoreMetricas.metricas?.fuerza.valor} kg. en piernas.
                            </p>
                          ) : (
                            <p className={style.txtResumen}>
                              {" "}
                              No hay metrica de fuerza registrada para este dia
                            </p>
                          )}
                          {scoreMetricas.metricas?.salto ? (
                            <p className={style.txtResumen}>
                              {" "}
                              Se regitro la metrica{" "}
                              {scoreMetricas.metricas?.salto.nombre}: Salta{" "}
                              {scoreMetricas.metricas?.salto.valor} cm.
                            </p>
                          ) : (
                            <p className={style.txtResumen}>
                              {" "}
                              No hay metrica de salto registrada para este dia
                            </p>
                          )}
                          {scoreMetricas.metricas?.resistencia ? (
                            <p className={style.txtResumen}>
                              {" "}
                              Se regitro la metrica{" "}
                              {scoreMetricas.metricas?.resistencia.nombre}: Recorre 3
                              km en {scoreMetricas.metricas?.resistencia.valor}{" "}
                              minutos.
                            </p>
                          ) : (
                            <p className={style.txtResumen}>
                              {" "}
                              No hay metrica de resistencia registrada para este dia
                            </p>
                          )}
                        </div>
                      ) : (
                        <p className={style.txtResumen}>
                          No hay metricas registradas
                        </p>
                      )}
                    </div>
                    {user.id !== userLocal.id ? (
                      <div className={style.contComentarioVisitor}>
                        {scoreMetricas.score !== null ? (
                          <div className={style.contComentario}>
                            <p>Comentario:</p>
                            <p>{scoreMetricas.score?.comentario}</p>
                          </div>
                        ) : (
                          <div className={style.contComentario}>
                            <p>Sin comentarios adicionales</p>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className={style.contComentario}>
                        {!scoreMetricas || !scoreMetricas.score ? (
                          <div className={style.contComentario}>
                            <p>No es posible agregar un comentario</p>
                          </div>
                        ) : scoreMetricas.score.comentario === null ? (
                          <div className={style.contComentario}>
                            <p>Agregar comentario:</p>
                            <textarea
                              name=""
                              id=""
                              cols="10"
                              rows="5"
                              className={style.txtComentario}
                              onChange={(e) => setComentario(e.target.value)}
                            ></textarea>
                            <button
                              className={style.btnGuardar}
                              onClick={agregarComentario}
                            >
                              Guardar
                            </button>
                          </div>
                        ) : (
                          <div className={style.contComentario}>
                            <p>Comentario:</p>
                            <p>{scoreMetricas.score.comentario}</p>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ModalScore;
