import React, { useEffect, useState } from "react";
import styles from "./Stadistics.module.css";
import { useDispatch } from "react-redux";
import userDefault from "../../assets/iconUser.png";
import { getUser } from "../../actions";
import Escudo from "./Componentes/Escudo";
import Equipo from "./Componentes/Equipo";
import Verificadas from "./Componentes/Verificadas";

// Formatea la fecha al formato "mes año" en español
const formatearFecha = (date) => {
  return date.toLocaleDateString("es-ES", {
    year: "numeric",
    month: "long",
  });
};

// Componente principal que muestra las estadísticas del usuario
export default function Stadistics({
  metricaClick,         // Función para manejar clicks en métricas
  entrenamientos,       // Lista de entrenamientos del usuario
  setModalEntrenamientoBool,
  setNavSecondActive
}) {
  const dispatch = useDispatch();

  // Datos del usuario desde localStorage
  const detailUser = JSON.parse(localStorage.getItem("dataUser"));
  
  // Estados
  const [currentDate, setCurrentDate] = useState(new Date());
  const [rachaActual, setRachaActual] = useState(0);

  // Calcula días consecutivos de entrenamiento
  function getRachaActual() {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const sortedEntrenamientos = entrenamientos
      .map((ent) => ({
        ...ent,
        normalizedDate: new Date(new Date(ent.createdAt).setHours(0, 0, 0, 0)),
      }))
      .sort((a, b) => b.normalizedDate - a.normalizedDate);
    let streak = 0;
    let currentDate = today;
    for (const ent of sortedEntrenamientos) {
      if (ent.normalizedDate.getTime() === currentDate.getTime()) {
        streak++;
        currentDate.setDate(currentDate.getDate() - 1);
      } else if (ent.normalizedDate < currentDate) {
        break;
      }
    }
    setRachaActual(streak);
  }

  // Efectos
  useEffect(() => {
    getRachaActual();
  }, [entrenamientos]);

  useEffect(() => {
    dispatch(getUser(detailUser.id));
  }, [dispatch, detailUser.id]);

  // Manejador para abrir modal de entrenamiento
  const handlerClickEntrenamiento = () => {
    setModalEntrenamientoBool(true)
    setNavSecondActive(false)
    window.scrollTo(0, 0)
  }

  return (
    <div className={styles.conteiner}>
      <Escudo
        detailUser={detailUser}
        userDefault={userDefault}
      />
      <div className={styles.equipo}>
        <Equipo
          entrenamientos={entrenamientos}
          handlerClickEntrenamiento={handlerClickEntrenamiento}
          currentDate={currentDate}
          rachaActual={rachaActual}
        />
        <Verificadas
          detailUser={detailUser}
          metricaClick={metricaClick}
        />
      </div>
    </div>
  );
}
