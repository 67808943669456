import styles from "./User.module.css";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { editarPerfil } from "../../actions";
import { formatoLegible } from "../funciones.js";
import Posicion from "./components/Posicion";
import Habilidades from "./components/Habilidades";
import Metricas from "./components/Metricas.jsx";
import Score from "./components/Score.jsx";
import ModalScore from "../ModalScore/ModalScore.jsx";
import axios from "axios";
import { useEffect } from "react";
import { URL } from "../../link.js";

const User = ({entrenamientos}) => {
  const user = JSON.parse(localStorage.getItem("dataUser"));
  const [tab, setTab] = useState("habilidades");

  const [principal, setPrincipal] = useState("");
  const [secundaria, setSecundaria] = useState("");
  const [terciaria, setTerciaria] = useState("");
  const [editPosicion, setEditPosicion] = useState(false);
  const [dateSelected, setDateSelected] = useState("")
  const [editHabilidades, setEditHabilidades] = useState(false);
  const [editMetricas, setEditMetricas] = useState(false);
  const [habilidadesSeleccionadas, setHabilidadesSeleccionadas] = useState(
    user.habilidadesSeleccionadas
  );
  const [valores, setValores] = useState({
    paseCorto: user.paseCorto,
    paseLargo: user.paseLargo,
    cabeceo: user.cabeceo,
    regate: user.regate,
    defensa: user.defensa,
    tiros: user.tiros,
  });
  const [scores, setScores] = useState([])
  const [modalScore, setModalScore] = useState(false)
  const dispatch = useDispatch();

  const editP = () => {
    setEditPosicion(!editPosicion);
  };

  const editH = () => {
    setEditHabilidades(!editHabilidades);
  };

  const editM = () => {
    setEditMetricas(!editMetricas);
  };

  const Guardar = async () => {
    user.posicion = principal || user.posicion;
    user.posicionesSeleccionadas = [
      secundaria || user.posicionesSeleccionadas[0],
      terciaria || user.posicionesSeleccionadas[1],
    ];
    user.habilidadesSeleccionadas =
      habilidadesSeleccionadas || user.habilidadesSeleccionadas;
    user.paseCorto = valores.paseCorto || user.paseCorto;
    user.paseLargo = valores.paseLargo || user.paseLargo;
    user.cabeceo = valores.cabeceo || user.cabeceo;
    user.regate = valores.regate || user.regate;
    user.defensa = valores.defensa || user.defensa;
    user.tiros = valores.tiros || user.tiros;

    const response = await dispatch(editarPerfil(user.id, user));
    localStorage.setItem("dataUser", JSON.stringify(response));
    setEditPosicion(false);
    setEditHabilidades(false);
    setEditMetricas(false);
  };

  const onChanceInputs = (habilidad) => {
    const index = habilidadesSeleccionadas.indexOf(habilidad);
    if (index === -1) {
      setHabilidadesSeleccionadas([...habilidadesSeleccionadas, habilidad]);
    } else {
      const nuevasHabilidades = [...habilidadesSeleccionadas];
      nuevasHabilidades.splice(index, 1);
      setHabilidadesSeleccionadas(nuevasHabilidades);
    }
  };

  const getScores = async () => {
    try {
        const response = await axios.get(`${URL}/score/${user.id}`)
        setScores(response.data)
    } catch (error) {
        console.log(error)
    }
  }

  const handleChange = (campo, valor) => {
    setValores((prevState) => ({ ...prevState, [campo]: valor }));
  };

  const posicionesLegibles = user.posicionesSeleccionadas.map(formatoLegible);

  const openModalScore = (date) => {
    setModalScore(true)
    setDateSelected(date)
  }

  useEffect(() => {
    getScores()
  }, [])

  return (
    <div className={styles.conteiner}>
      <Posicion
        user={user}
        editPosicion={editPosicion}
        editP={editP}
        Guardar={Guardar}
        posicionesLegibles={posicionesLegibles}
        formatoLegible={formatoLegible}
        setPrincipal={setPrincipal}
        setSecundaria={setSecundaria}
        setTerciaria={setTerciaria}
      />
      <Habilidades
        user={user}
        editHabilidades={editHabilidades}
        editH={editH}
        Guardar={Guardar}
        habilidadesSeleccionadas={habilidadesSeleccionadas}
        onChanceInputs={onChanceInputs}
        handleChange={handleChange}
      />
      <div className={styles.contTitle}>
        <h3 className={styles.seccion}>METRICAS PERSONALES</h3>
        {tab === "habilidades" && <p className={styles.btnTxt} onClick={editM}>
          EDITAR
        </p>}
      </div>
      <div className={styles.tab}>
        <div className={tab === "habilidades" ? styles.tabItemActive : styles.tabItem} onClick={() => setTab("habilidades")}>
          <p className={styles.tabText}>HABILIDADES</p>
        </div>
        <div className={tab === "bsd" ? styles.tabItemActive : styles.tabItem} onClick={() => setTab("bsd")}>
          <p className={styles.tabText}>BSD SCORE</p>
        </div>
      </div>
     {tab === "habilidades" ? 
     <Metricas
        user={user}
        editMetricas={editMetricas}
        editM={editM}
        Guardar={Guardar}
        valores={valores}
        handleChange={handleChange}
      /> : 
      <Score 
      user={user}
      getScoresByVisitor={null}
      getEntrenamientosByVisitor={null}
      isVisitor={false}
      openModalScore={openModalScore}
      />}
     { modalScore && <ModalScore
      user={user}
      setModalScore={setModalScore}
      dateSelected={dateSelected}
      />}
    </div>
  );
};

export default User;
