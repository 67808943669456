import React from "react";
import styles from "../User.module.css";
import { habilidades } from "../../funciones.js";


const Habilidades = ({user, editHabilidades, editH, Guardar, habilidadesSeleccionadas, onChanceInputs}) => {
  return (
    <div>
      {!editHabilidades ? (
        <div className={styles.habilidades}>
          <div className={styles.contTitle}>
            <h3 className={styles.seccion}>HABILIDADES DESTACADAS</h3>
            <p className={styles.btnTxt} onClick={editH}>
              EDITAR
            </p>
          </div>
          <div className={styles.contHabilidades}>
            {user.habilidadesSeleccionadas.map((habilidad, index) => (
              <div key={index} className={styles.habi}>
                <p className={styles.txt}>{habilidad}</p>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className={styles.habilidades}>
          <h3 className={styles.seccion}>HABILIDADES DESTACADAS</h3>
          <div className={styles.contOpciones}>
            <div className={styles.opciones}>
              {habilidades.map((habilidad, index) => (
                <div key={index} className={styles.opcionh}>
                  <div className={styles.contCheck}>
                    <input
                      type="checkbox"
                      className={styles.checkbox}
                      onChange={() => onChanceInputs(habilidad.nombre)}
                      checked={habilidadesSeleccionadas.includes(
                        habilidad.nombre
                      )}
                    />
                    <label className={styles.labelCheckbox}>
                      <span className={styles.textCheck}>
                        {habilidad.nombre}
                      </span>
                    </label>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className={styles.cont}>
            <button className={styles.btnRegresar} onClick={editH}>
              Cancelar
            </button>
            <button className={styles.btnContinuar} onClick={Guardar}>
              Guardar
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Habilidades;
