export  const opciones = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: (context) => {
            return `Fecha: ${context[0].label}`;
          },
          label: (context) => {
            return `Minutos: ${context.raw}`;
          },
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          color: "rgba(21, 26, 32, 1)",
          display: false,
        },
        ticks: {
          color: "rgba(255, 255, 255, 1)",
          maxRotation: 45,
          minRotation: 45,
        },
      },
      y: {
        grid: {
          color: "rgba(21, 26, 32, 1)",
          display: false,
        },
        ticks: {
          color: "rgba(255, 255, 255, 1)",
          display: true,
          callback: (value) => `${value}min`,
        },
      },
    },
    elements: {
      line: {
        tension: 0.4,
      },
      point: {
        radius: 4,
        backgroundColor: "rgba(228, 253, 63, 1)",
      },
    },
  };