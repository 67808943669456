export const handleRangoChange = (tipo, fecha, setRangoFechas) => {
  try {
    if (!fecha || isNaN(new Date(fecha).getTime())) {
      const fechaFin = new Date();
      const fechaInicio = new Date();
      fechaInicio.setDate(fechaInicio.getDate() - 7);

      fechaInicio.setHours(0, 0, 0, 0);
      fechaFin.setHours(23, 59, 59, 999);

      setRangoFechas({
        inicio: fechaInicio.toISOString(),
        fin: fechaFin.toISOString()
      });
      return;
    }

    const nuevaFecha = new Date(fecha + "T00:00:00-06:00");

    if (tipo === "fin") {
      nuevaFecha.setHours(23, 59, 59, 999);
    } else {
      nuevaFecha.setHours(0, 0, 0, 0);
    }

    setRangoFechas((prev) => ({
      ...prev,
      [tipo]: nuevaFecha.toISOString()
    }));
  } catch (error) {
    console.error("Error al procesar la fecha:", error);
    const fechaFin = new Date();
    const fechaInicio = new Date();
    fechaInicio.setDate(fechaInicio.getDate() - 7);

    fechaInicio.setHours(0, 0, 0, 0);
    fechaFin.setHours(23, 59, 59, 999);

    setRangoFechas({
      inicio: fechaInicio.toISOString(),
      fin: fechaFin.toISOString()
    });
  }
};