export function setDataScore(scoreByDate, setData, labels) {
  if (scoreByDate && scoreByDate.length > 0) {
    // Ordenar los scores por fecha
    const sortedScores = [...scoreByDate].sort(
      (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
    );

    // Crear un objeto para mapear fechas a valores
    const valoresPorFecha = {};
    
    // Mapear los valores a sus fechas correspondientes
    sortedScores.forEach(score => {
      const fecha = new Date(score.createdAt);
      const fechaMX = new Date(fecha.toLocaleString('en-US', { timeZone: 'America/Mexico_City' }));
      const dia = fechaMX.getDate().toString().padStart(2, '0');
      const mes = (fechaMX.getMonth() + 1).toString().padStart(2, '0');
      const año = fechaMX.getFullYear().toString().slice(-2);
      const fechaKey = `${dia}/${mes}/${año}`;
      valoresPorFecha[fechaKey] = score.valor;
    });

    // Crear array de valores alineados con los labels, manteniendo el último valor conocido
    const valores = labels.map((label, index) => {
      if (valoresPorFecha[label]) {
        return valoresPorFecha[label];
      }
      // Buscar el último valor conocido
      for (let i = index; i >= 0; i--) {
        if (valoresPorFecha[labels[i]]) {
          return valoresPorFecha[labels[i]];
        }
      }
      return null; // Solo si no hay valores previos
    });

    setData({
      label: "Score",
      data: valores,
      borderColor: "rgba(228, 253, 63, 1)",
      backgroundColor: "rgba(228, 253, 63, 0.2)",
      fill: true,
      tension: 0.4,
      pointRadius: 4,
      spanGaps: true, // Esto ayuda a conectar los puntos aunque haya gaps
      pointBackgroundColor: "rgba(228, 253, 63, 1)",
    });
  }
}