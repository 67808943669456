import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Line } from "react-chartjs-2";
import styles from "../User.module.css";
import "react-datepicker/dist/react-datepicker.css";
import { getScoreByDate, getEntrenamientosByDate } from "../../../actions";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { setDataScore } from "../utils/setDataScore";
import { handleRangoChange } from "../utils/handleRangoChange";
//import { options } from "../utils/options";
import { generaDataEntrenamiento } from "../utils/generaDataEntrenamiento";
import { getlabels } from "../utils/labels";
import { generaDataLinea } from "../utils/generaDataLinea";
import { getDataLineas } from "../utils/getDataLineas";
import { generaDataConstancia } from "../utils/generaDataConstancia";

// Registrar los componentes necesarios
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Score = ({
  user,
  getScoresByVisitor,
  getEntrenamientosByVisitor,
  isVisitor,
  openModalScore
}) => {
  console.log(user)
  const dispatch = useDispatch();

  const scoreByDate = useSelector((state) => state.scoreByDate);
  const entrenamientosByDate = useSelector(
    (state) => state.entrenamientosByDate
  );

  const [scoreByDateVisitor, setScoreByDateVisitor] = useState([]);
  const [entrenamientosByDateVisitor, setEntrenamientosByDateVisitor] =
    useState([]);

  const [data, setData] = useState({});
  const [dataNoventa, setDataNoventa] = useState([]);
  const [dataOchenta, setDataOchenta] = useState([]);
  const [dataSetenta, setDataSetenta] = useState([]);
  const [dataEntrenamiento, setDataEntrenamiento] = useState([]);
  const [dataConstancia, setDataConstancia] = useState([]);
  const [labels, setLabels] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [rangoFechas, setRangoFechas] = useState(() => {
    // Obtener fecha actual en la zona horaria local
    const fechaFin = new Date();
    const fechaInicio = new Date();

    // Ajustar a la zona horaria local
    const offset = fechaFin.getTimezoneOffset();
    fechaFin.setMinutes(fechaFin.getMinutes() - offset);
    fechaInicio.setMinutes(fechaInicio.getMinutes() - offset);

    // Configurar rango de 7 días
    fechaInicio.setDate(fechaInicio.getDate() - 7);

    // Configurar horas
    fechaInicio.setHours(0, 0, 0, 0);
    fechaFin.setHours(23, 59, 59, 999);

    return {
      inicio: fechaInicio.toISOString(),
      fin: fechaFin.toISOString(),
    };
  });



  const formatDateForInput = (isoString) => {
    const date = new Date(isoString);
    const offset = date.getTimezoneOffset();
    const localDate = new Date(date.getTime() - offset * 60 * 1000);
    return localDate.toISOString().split("T")[0];
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const newLabels = getlabels(rangoFechas);
      setLabels(newLabels);
      try {
        let payload = {
          inicio: rangoFechas.inicio,
          fin: rangoFechas.fin,
          userId: user.id,
        };

        if (isVisitor) {
          const scoreData = await getScoresByVisitor(payload);
          console.log(scoreData)
          const entrenamientosData = await getEntrenamientosByVisitor(payload);
          console.log(entrenamientosData)
          setScoreByDateVisitor(scoreData);
          setEntrenamientosByDateVisitor(entrenamientosData);
        } else {
          dispatch(getEntrenamientosByDate(payload));
          dispatch(getScoreByDate(payload));
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [rangoFechas]);

  useEffect(() => {
    
    if (isVisitor) {
        setDataScore(scoreByDateVisitor, setData, labels);
        generaDataEntrenamiento(entrenamientosByDateVisitor, setDataEntrenamiento, labels);
        setDataConstancia(generaDataConstancia(entrenamientosByDateVisitor, labels));
    } else {
      setDataScore(scoreByDate, setData, labels);
      generaDataEntrenamiento(entrenamientosByDate, setDataEntrenamiento, labels);
      setDataConstancia(generaDataConstancia(entrenamientosByDate, labels));
    }

    const dataNoventa = generaDataLinea(rangoFechas, 90);
    const dataOchenta = generaDataLinea(rangoFechas, 80);
    const dataSetenta = generaDataLinea(rangoFechas, 70);
    
    setDataNoventa(getDataLineas(dataNoventa, "noventa"));
    setDataOchenta(getDataLineas(dataOchenta, "ochenta"));
    setDataSetenta(getDataLineas(dataSetenta, "setenta"));
  }, [scoreByDate, entrenamientosByDate, scoreByDateVisitor, entrenamientosByDateVisitor]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
      },
      tooltip: {
        enabled: true,
        mode: 'index',
        intersect: false,
        filter: function(tooltipItem) {
          return tooltipItem.dataset.tooltip?.enabled !== false;
        },
        callbacks: {
          title: (context) => {
            if (!context || !context[0]) return '';
            return `Fecha: ${context[0].label}`;
          },
          label: (context) => {
            if (context.dataset.tooltip?.callbacks?.label) {
              return context.dataset.tooltip.callbacks.label(context);
            }
            const value = context.raw !== undefined ? context.raw : '';
            return `${context.dataset.label}: ${value}`;
          }
        }
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: "white",
          maxRotation: 45,
          minRotation: 45,
          autoSkip: true,
          maxTicksLimit: 10,
        },
      },
      y: {
        grid: {
          color: "rgba(255, 255, 255, 0.1)",
        },
        min: 0,
        max: 100,
        ticks: {
          color: "white",
          stepSize: 2,
        },
      },
    },
    onClick: (event, elements, context) => {
      openModalScore(context.tooltip.title[0])
    },
  };

  return (
    <div>
      <div className={styles.rangoFechas}>
        <div className={styles.fechaInput}>
          <label>Desde:</label>
          <input
            type="date"
            value={formatDateForInput(rangoFechas.inicio)}
            onChange={(e) =>
              handleRangoChange("inicio", e.target.value, setRangoFechas)
            }
            max={formatDateForInput(new Date().toISOString())}
          />
        </div>

        <div className={styles.fechaInput}>
          <label>Hasta:</label>
          <input
            type="date"
            value={formatDateForInput(rangoFechas.fin)}
            onChange={(e) =>
              handleRangoChange("fin", e.target.value, setRangoFechas)
            }
            min={formatDateForInput(rangoFechas.inicio)}
            max={formatDateForInput(new Date().toISOString())}
          />
        </div>
      </div>
      <div className={styles.scoreChart}>
        <Line
          data={{
            labels: labels,
            datasets: [
              data,
              dataNoventa,
              dataOchenta,
              dataSetenta,
              dataEntrenamiento[1],
              dataEntrenamiento[0],
              dataConstancia,
            ],
          }}
          options={options}
        />
      </div>
    </div>
  );
};

export default Score;
