import React, { useState, useEffect, useRef } from "react";
import style from "./ModalEntrenamiento.module.css";
import { useDispatch } from "react-redux";
import { getEntrenamientos } from "../../actions/index.js";
import Calendario from "./Componentes/Calendario";
import ContenidoMetrica from "./Componentes/ContenidoMetrica";
import AgregarMetrica from "./Componentes/AgregarMetrica";
import { opciones } from "./utils/opciones";
import { getDatesInRange } from "./utils/getDatesInRange";
import { handleRangoChange } from "./utils/handleRangoChange";
import { getRachaMaxima } from "./utils/getRachaMaxima";
import { getRachaActual } from "./utils/getRachaActual";
import { generateDays } from "./utils/generateDays";
import { getTiempoDia } from "./utils/getTiempoDia";
import { obtenerGolesDelMes } from "./utils/obtenerGolesDelMes";
import { getTiempoMesEntrenamiento } from "./utils/getTiempoMesEntrenamiento";
import { getTiempoMesJugado } from "./utils/getTiempoMesJugado";

const ModalEntrenamiento = ({
  setModalEntrenamientoBool,
  entrenamientos,
  user,
  setNavSecondActive
}) => {

  const dispatch = useDispatch();

  const [agregarBool, setAgregarBool] = useState(false);
  const [arrayEntrenamientos, setArrayEntrenamientos] =
    useState(entrenamientos);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [daysInMonth, setDaysInMonth] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [tiempoDia, setTiempoDia] = useState(0);
  const [tiempoMesEntrenamiento, setTiempoMesEntrenamiento] = useState(0);
  const [tiempoMesJugado, setTiempoMesJugado] = useState(0);
  const [hasUploadedMetric, setHasUploadedMetric] = useState(false);
  const [rachaActual, setRachaActual] = useState(0);
  const [mensaje, setMensaje] = useState("");
  const [golesMes, setGolesMes] = useState(0);
  const [rachaMaxima, setRachaMaxima] = useState(0);
  const [data, setData] = useState({
    labels: [],
    datasets: [
      {
        label: "Entrenamientos",
        data: [],
        backgroundColor: "rgba(228, 253, 63, 0.37)",
        borderColor: "rgba(228, 253, 63, 1)",
        borderWidth: 2,
        fill: true,
      },
    ],
  });
  const [rangoFechas, setRangoFechas] = useState(() => {
    const fechaFin = new Date();
    const fechaInicio = new Date();
    fechaInicio.setDate(fechaInicio.getDate() - 7);
    fechaInicio.setHours(0, 0, 0, 0);
    fechaFin.setHours(23, 59, 59, 999);

    return { inicio: fechaInicio, fin: fechaFin };
  });

  // Nuevo estado para controlar si estamos viendo métricas por rango
  const [isRangeView, setIsRangeView] = useState(false);

  function actualizarDatos() {
    const fechasRango = getDatesInRange(rangoFechas.inicio, rangoFechas.fin);
    const datosPorDia = {};
    fechasRango.forEach((fecha) => {
      datosPorDia[fecha.toLocaleDateString("es-MX")] = 0;
    });
    arrayEntrenamientos.forEach((entrenamiento) => {
      const fecha = new Date(entrenamiento.createdAt);
      if (fecha >= rangoFechas.inicio && fecha <= rangoFechas.fin) {
        const fechaKey = fecha.toLocaleDateString("es-MX");
        datosPorDia[fechaKey] =
          (datosPorDia[fechaKey] || 0) + entrenamiento.tiempo;
      }
    });

    const labels = fechasRango.map((fecha) =>
      fecha.toLocaleDateString("es-MX", {
        day: "2-digit",
        month: "short",
      })
    );
    const valores = Object.values(datosPorDia);
    setData({
      labels: labels,
      datasets: [
        {
          label: "Entrenamientos",
          data: valores,
          backgroundColor: "rgba(228, 253, 63, 0.37)",
          borderColor: "rgba(228, 253, 63, 1)",
          borderWidth: 2,
          fill: true,
        },
      ],
    });
  }

  useEffect(() => {
    generateDays(currentDate, entrenamientos, setDaysInMonth);
  }, [currentDate, entrenamientos]);

  const handleMonthChange = (direction) => {
    const today = new Date();
    
    setCurrentDate((prevDate) => {
      const newMonth = direction === "prev"
        ? prevDate.getMonth() - 1
        : prevDate.getMonth() + 1;
      
      const newDate = new Date(prevDate.getFullYear(), newMonth, 1);

      // Prevenir navegación más allá del mes actual
      if (newDate > today) {
        return prevDate;
      }

      return newDate;
    });
  };

  const handleClick = () => {
    if (hasUploadedMetric) {
      setMensaje("Ya subiste una métrica el día de hoy.");
    } else {
      setAgregarBool(true);
      setMensaje("");
    }
  };

  const handlerClickCerrar = () => {
    setModalEntrenamientoBool(false)
    setNavSecondActive(true)
    window.scrollTo(0, 0)
  }

  useEffect(() => {
    if (arrayEntrenamientos && arrayEntrenamientos.length > 0) {
      actualizarDatos();
    }
  }, [rangoFechas, arrayEntrenamientos]);

  useEffect(() => {
    getRachaActual(entrenamientos, setRachaActual);
    getRachaMaxima(entrenamientos, setRachaMaxima);
  }, [entrenamientos]);

  useEffect(() => {
    if (isRangeView) {
      // Obtener métricas del rango seleccionado
      getTiempoDia(arrayEntrenamientos, rangoFechas, setTiempoDia);
      getTiempoMesEntrenamiento(arrayEntrenamientos, rangoFechas, setTiempoMesEntrenamiento);
      getTiempoMesJugado(arrayEntrenamientos, rangoFechas, setTiempoMesJugado);
      obtenerGolesDelMes(entrenamientos, rangoFechas, setGolesMes);
    } else {
      // Obtener métricas del día seleccionado
      getTiempoDia(arrayEntrenamientos, selectedDate, setTiempoDia);
      getTiempoMesEntrenamiento(arrayEntrenamientos, selectedDate, setTiempoMesEntrenamiento);
      getTiempoMesJugado(arrayEntrenamientos, selectedDate, setTiempoMesJugado);
      obtenerGolesDelMes(entrenamientos, selectedDate, setGolesMes);
    }
    actualizarDatos();
  }, [selectedDate, rangoFechas, isRangeView]);

  useEffect(() => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Normalizar a medianoche
    // Verificar si hay una métrica con fecha de hoy
    const metricUploadedToday = entrenamientos.some((ent) => {
      const metricDate = new Date(ent.createdAt);
      metricDate.setHours(0, 0, 0, 0); // Normalizar fecha del entrenamiento
      return metricDate.getTime() === today.getTime();
    });

    setHasUploadedMetric(metricUploadedToday);
  }, [entrenamientos]);

  const handleMetricaAgregada = (nuevosEntrenamientos) => {
    setArrayEntrenamientos(nuevosEntrenamientos);
    dispatch(getEntrenamientos(user.id));
    setSelectedDate(new Date());
    setAgregarBool(false);
  };

  // Modificar la función handleRangoChange
  const handleRangeChange = (tipo, fecha) => {
    handleRangoChange(tipo, fecha, setRangoFechas);
    setIsRangeView(true); // Activar vista por rango
  };

  // Agregar función para resetear a vista diaria
  const resetToDaily = () => {
    setIsRangeView(false);
    setSelectedDate(new Date());
  };

  const isCurrentMonth = () => {
    const today = new Date();
    return currentDate.getMonth() === today.getMonth() && 
           currentDate.getFullYear() === today.getFullYear();
  };

  return (
    <div className={style.modalEntrenamiento}>
      <div className={style.modalContent}>
        <Calendario
          currentDate={currentDate}
          handleMonthChange={handleMonthChange}
          daysInMonth={daysInMonth}
          tiempo={tiempoDia}
          isCurrentMonth={isCurrentMonth()}
        />
        {!agregarBool ? (
          <ContenidoMetrica
            setModalEntrenamientoBool={setModalEntrenamientoBool}
            selectedDate={selectedDate}
            tiempoDia={tiempoDia}
            tiempoMesEntrenamiento={tiempoMesEntrenamiento}
            tiempoMesJugado={tiempoMesJugado}
            golesMes={golesMes}
            rangoFechas={rangoFechas}
            data={data}
            opciones={opciones}
            handleRangoChange={handleRangeChange}
            handleClick={handleClick}
            mensaje={mensaje}
            setRangoFechas={setRangoFechas}
            isRangeView={isRangeView}
            setIsRangeView={setIsRangeView}
            resetToDaily={resetToDaily}
            handlerClickCerrar={handlerClickCerrar}
          />
        ) : (
          <AgregarMetrica
            setModalEntrenamientoBool={setModalEntrenamientoBool}
            user={user}
            onMetricaAgregada={handleMetricaAgregada}
            rachaActual={rachaActual}
            rachaMaxima={rachaMaxima}
            setAgregarBool={setAgregarBool}
            handlerClickCerrar={handlerClickCerrar}
          />
        )}
      </div>
    </div>
  );
};

export default ModalEntrenamiento;
