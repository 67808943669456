import { format } from 'date-fns';

export const arrayFechas = (array) => {
    // Ordenar el array de objetos por fecha de manera descendente
    const sortedArray = array.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));


    // Extraer las fechas ordenadas del array ordenado
    const fechas = sortedArray.map(item => fechaFormat(item.createdAt));

    return fechas;
}

export const arrayValores = (array) => {
    const sortedArray = array.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
    return sortedArray.map(item => item.valor);
};

export const fechaFormat = (date) => {
    return format(new Date(date), `dd/MM/yyyy`)
}

export const fechaFormateada = (date) => {
    return format(new Date(date), `dd/MM/yyyy | HH:mm`)
}