import React from 'react'
import styles from "../Stadistics.module.css"
import { calcularEdad } from '../../funciones'

// Componente que muestra la información principal del perfil del usuario
const Escudo = ({
  detailUser,      // Datos completos del usuario
  userDefault      // Imagen por defecto si no hay foto de perfil
}) => {
  // Valores por defecto para evitar errores con datos nulos
  const defaultValues = {
    foto: userDefault,
    nombre: "Sin nombre",
    direccion: "Sin dirección",
    origen: "Sin origen",
    fondo: "Sin equipo",
    altura: 0,
    peso: 0,
    pie: "",
    scores: [{ valor: 0 }]
  };

  // Combinar valores por defecto con los datos del usuario
  const userData = { ...defaultValues, ...detailUser };

  return (
    <div className={styles.fondoEscudo}>
      <div className={styles.contFoto}>
        {/* Foto de perfil */}
        <img
          className={styles.foto}
          src={userData.foto || userDefault}
          alt=""
        />
        
        {/* Información básica */}
        <h3 className={styles.name}>{userData.nombre}</h3>
        <h4 className={styles.direccion}>
          {userData.direccion}, {userData.origen}
        </h4>
        <h3 className={styles.name}>{userData.fondo || "Sin equipo"}</h3>

        {/* Datos físicos - primera fila */}
        <div className={styles.contUserInfo}>
          <div className={styles.userInfo}>
            <div className={styles.info}>
              <h3 className={styles.dataName}>EDAD</h3>
              <h4 className={styles.dataInfo}>
                {calcularEdad(
                  userData.dia,
                  userData.mes,
                  userData.anio
                )}{" "}
                años
              </h4>
            </div>
          </div>
          <div className={styles.userInfo}>
            <div className={styles.infoB}>
              <h3 className={styles.dataName}>ALTURA</h3>
              <h4 className={styles.dataInfo}>{userData.altura / 100}m</h4>
            </div>
          </div>
        </div>

        {/* Datos físicos - segunda fila */}
        <div className={styles.contUserInfo}>
          <div className={styles.userInfo}>
            <div className={styles.info}>
              <h3 className={styles.dataName}>PESO</h3>
              <h4 className={styles.dataInfo}>{userData.peso}kg</h4>
            </div>
          </div>
          <div className={styles.userInfo}>
            <div className={styles.infoB}>
              <h3 className={styles.dataName}>PIE</h3>
              <h4 className={styles.dataInfo}>
                {userData.pie?.slice(0, 3).toUpperCase()}
              </h4>
            </div>
          </div>
        </div>

        {/* Puntuación BSD */}
        <div className={styles.contBSD}>
          <h3 className={styles.bsdT}>BSD SCORE</h3>
          <h1 className={styles.score}>{userData?.scores?.[0]?.valor ?? 0}</h1>
        </div>
      </div>
    </div>
  )
}

export default Escudo
