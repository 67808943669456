import React from 'react'
import styles from "../User.module.css"

const RenderCalificaciones = (campo, handleChange, valores) => {
    const calificaciones = [];
    for (let i = 1; i <= 5; i++) {
      calificaciones.push(
        <div
          key={i}
          className={
            valores[campo] === i ? styles.caliSeleccionado : styles.cali
          }
          onClick={() => handleChange(campo, i)}
        >
          <p className={styles.caliText}>{i}</p>
        </div>
      );
    }
    return calificaciones;
}

export default RenderCalificaciones
