import React, { useRef, useState } from "react";
import style from "../ModalEntrenamiento.module.css";
import { useVideoUpload } from '../hooks/useVideoUpload';
import { URL } from "../../../link.js";
import axios from 'axios';
import PropTypes from 'prop-types';

// Componente para seleccionar el tipo de actividad (Entrenamiento/Partido)
const TipoSelector = ({ tipo, setTipo }) => (
  <div className={style.contSelect}>
    <div
      className={tipo === "Entrenamiento" ? style.selected : style.notSelected}
      onClick={tipo === "Entrenamiento" ? null : () => setTipo("Entrenamiento")}
    >
      <p className={tipo === "Entrenamiento" ? style.txtSelect : style.txtNotSelect}>
        Entrenamiento
      </p>
    </div>
    <div
      className={tipo === "Partido" ? style.selected : style.notSelected}
      onClick={tipo === "Partido" ? null : () => setTipo("Partido")}
    >
      <p className={tipo === "Partido" ? style.txtSelect : style.txtNotSelect}>
        Partido
      </p>
    </div>
  </div>
);

// Componente que muestra las rachas actual y máxima
const RachasDisplay = ({ rachaActual, rachaMaxima }) => (
  <>
    <p className={style.title}>RACHAS</p>
    <div className={style.contRachas}>
      <div className={style.racha}>
        <p className={style.txt}>Racha actual</p>
        <div className={style.contValor}>
          <p className={style.valor}>{rachaActual} dias</p>
        </div>
      </div>
      <div className={style.racha}>
        <p className={style.txt}>Racha maxima</p>
        <div className={style.contValorM}>
          <p className={style.valor}>{rachaMaxima} dias</p>
        </div>
      </div>
    </div>
  </>
);

// Componente principal para agregar métricas de entrenamiento/partido
const AgregarMetrica = ({  
  user, 
  onMetricaAgregada,
  rachaActual,
  rachaMaxima,
  setAgregarBool,
  handlerClickCerrar
}) => {
  // Estados para manejar el formulario
  const [tipo, setTipo] = useState("Entrenamiento");
  const [tiempo, setTiempo] = useState("");
  const [goles, setGoles] = useState("");
  const fileInputVideoRef = useRef(null);
  
  // Hook personalizado para manejar la carga de videos
  const {
    loadingVideo,
    videoLoadingInProgress,
    upLoadVideo,
    uploadVideo,
    resetVideo,
    uploadProgress
  } = useVideoUpload("videoUpload", "dvwtgluwc");

  // Maneja el click en el botón de video
  const handleVideoUpload = () => {
    if (upLoadVideo) {
      resetVideo();
    } else {
      fileInputVideoRef.current.click();
    }
  };

  // Envía los datos del entrenamiento/partido al servidor
  const postEntrenamiento = async () => {
    const data = {
      tipo,
      tiempo: Number(tiempo) || 0,
      entreno: true,
      goles: Number(goles) || null,
      userId: user.id,
      video: upLoadVideo,
    };

    try {
      const response = await axios.post(`${URL}/entrenamiento/nuevo`, data);
      onMetricaAgregada(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  // Determina el texto del botón de video según el estado
  const getVideoButtonText = () => {
    if (videoLoadingInProgress) {
      return `Subiendo... ${uploadProgress}%`;
    }
    if (upLoadVideo) {
      return "Cambiar video";
    }
    return "Agregar video";
  };

  return (
    <div className={style.agregarMetricaCont}>
      <button
        className={style.btnCerrar}
        onClick={handlerClickCerrar}
      >
        X
      </button>

      <RachasDisplay 
        rachaActual={rachaActual} 
        rachaMaxima={rachaMaxima} 
      />
      
      <p className={style.subTitle}>Guardad estadísticas del día</p>
      <TipoSelector tipo={tipo} setTipo={setTipo} />
      
      <p className={style.subTitle}>Tiempo total de entrenamiento</p>
      <div className={style.contInput}>
        {/* Input para tiempo */}
        <input
          className={style.inp}
          type="text"
          name="tiempo"
          placeholder="00"
          onChange={(e) => setTiempo(e.target.value)}
          value={tiempo}
        />
        <span className={style.unidad}>minutos</span>
        
        {/* Input condicional para goles en caso de ser partido */}
        {tipo === "Partido" && (
          <>
            <input
              className={style.inp2}
              type="text"
              name="goles"
              placeholder="0"
              onChange={(e) => setGoles(e.target.value)}
              value={goles}
            />
            <span className={style.unidad2}>goles</span>
          </>
        )}

        {/* Input oculto para subir video */}
        <input
          type="file"
          ref={fileInputVideoRef}
          onChange={(e) => {
            if (e.target.files[0]) {
              uploadVideo(e.target.files[0]);
            }
          }}
          className={style.inputext}
          accept="video/*"
          disabled={videoLoadingInProgress}
        />
        
        {/* Botón para manejar la subida de video */}
        <button 
          className={`${style.addMultimedia} ${videoLoadingInProgress ? style.loading : ''}`}
          onClick={handleVideoUpload}
          disabled={videoLoadingInProgress}
          style={{
            opacity: videoLoadingInProgress ? 0.7 : 1,
            cursor: videoLoadingInProgress ? 'not-allowed' : 'pointer'
          }}
        >
          {getVideoButtonText()}
        </button>
      </div>

      {/* Botón para guardar la métrica */}
      <button 
        className={style.btnAgregar} 
        onClick={postEntrenamiento}
        disabled={!tiempo || videoLoadingInProgress}
      >
        Guardar
      </button>
      <button 
        className={style.btnCancelar} 
        onClick={() => {setAgregarBool(false)}}
      >
        Cancelar
      </button>
    </div>
  );
};

// Validación de props
AgregarMetrica.propTypes = {
  setModalEntrenamientoBool: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  onMetricaAgregada: PropTypes.func.isRequired,
  rachaActual: PropTypes.number.isRequired,
  rachaMaxima: PropTypes.number.isRequired
};

RachasDisplay.propTypes = {
  rachaActual: PropTypes.number.isRequired,
  rachaMaxima: PropTypes.number.isRequired
};

TipoSelector.propTypes = {
  tipo: PropTypes.string.isRequired,
  setTipo: PropTypes.func.isRequired
};

export default AgregarMetrica;
