import React from "react";
import styles from "../User.module.css";
import { Radar } from "react-chartjs-2";

const MetricasVisitor = ({
    user,
    userLocal,
    handleRadar
}) => {
  return (
    <div className={styles.metricas}>
      <Radar
        className={styles.radar}
        data={{
          labels: [
            "PASES CORTOS",
            "PASES LARGOS",
            "CABECEO",
            "REGATE",
            "DEFENSA",
            "TIROS",
          ],
          datasets: [
            {
              label: "HABILIDADES",
              data: [
                user.paseCorto,
                user.paseLargo,
                user.cabeceo,
                user.regate,
                user.defensa,
                user.tiros,
              ],
              fill: true,
              backgroundColor: "rgba(228, 253, 63, 0.5)",
              borderColor: "rgb(228, 253, 63)",
            },
          ],
        }}
        options={{
          maintainAspectRatio: true,
          responsive: true,
          width: 280,
          height: 300,

          scales: {
            r: {
              min: 0,
              max: 5,
              ticks: {
                display: false,
              },
              pointLabels: {
                color: "rgb(255, 255, 255)",
              },
            },
          },
          plugins: {
            legend: {
              labels: {
                color: "white",
              },
            },
          },
        }}
      />
    </div>
  );
};

export default MetricasVisitor;
