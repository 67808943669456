import React from "react";
import styles from "../User.module.css";
import posicion from "../../../assets/Campo.png";
import {
  posicionOptions,
  habilidades,
  formatoLegible,
} from "../../funciones.js";

const Posicion = ({
  user,
  editPosicion,
  editP,
  Guardar,
  posicionesLegibles,
  formatoLegible,
  setPrincipal,
  setSecundaria,
  setTerciaria,
}) => {
  return (
    <div>
      {!editPosicion ? (
        <div className={styles.posicion}>
          <div className={styles.contTitle}>
            <h3 className={styles.seccion}>POSICIÓN DE JUEGO</h3>
            <p className={styles.btnTxt} onClick={editP}>
              EDITAR
            </p>
          </div>
          <img className={styles.campo} src={posicion} alt="" />
          <h3 className={styles.title}>POSICIÓN FAVORITA</h3>
          <div className={styles.contPosicion}>
            <div className={styles.posi}>
              <p className={styles.txt}>{formatoLegible(user.posicion)}</p>
            </div>
          </div>
          <h3 className={styles.title}>OTRAS POSICIONES</h3>
          <div className={styles.contPosicion}>
            <div className={styles.posi}>
              <p className={styles.txt}>{posicionesLegibles[0]}</p>
            </div>
            <div className={styles.posi}>
              <p className={styles.txt}>{posicionesLegibles[1]}</p>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.posicion}>
          <h3 className={styles.seccion}>POSICIÓN DE JUEGO</h3>
          <div className={styles.contInp}>
            <h3 className={styles.subT}>Posición principal</h3>
            <select
              className={styles.inputCampo}
              onChange={(e) => setPrincipal(e.target.value)}
            >
              {posicionOptions}
            </select>
          </div>
          <div className={styles.contInp}>
            <h3 className={styles.subT}>Segunda posición</h3>
            <select
              className={styles.inputCampo}
              onChange={(e) => setSecundaria(e.target.value)}
            >
              {posicionOptions}
            </select>
          </div>
          <div className={styles.contInp}>
            <h3 className={styles.subT}>Tercera posición</h3>
            <select
              className={styles.inputCampo}
              onChange={(e) => setTerciaria(e.target.value)}
            >
              {posicionOptions}
            </select>
          </div>
          <div className={styles.cont}>
            <button className={styles.btnRegresar} onClick={editP}>
              Cancelar
            </button>
            <button className={styles.btnContinuar} onClick={Guardar}>
              Guardar
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Posicion;
