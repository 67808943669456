import React from "react";
import styles from "../Stadistics.module.css"
import { filterMetricas } from "../../funciones";
import fuerza from "../../../assets/graficaFuerza.png";
import iconFuerza from "../../../assets/iconFuerza.png";
import velocidad from "../../../assets/graficaVelocidad.png";
import iconVelocidad from "../../../assets/iconVelocidad.png";
import resistencia from "../../../assets/graficaResistencia.png";
import iconResistencia from "../../../assets/iconResistencia.png";
import salto from "../../../assets/graficaSalto.png";
import iconSalto from "../../../assets/iconSalto.png";

// Configuración de las métricas disponibles
const METRICAS_CONFIG = [
  {
    tipo: "fuerza",
    imagen: fuerza,
    icono: iconFuerza,
    unidad: "kg"
  },
  {
    tipo: "velocidad",
    imagen: velocidad,
    icono: iconVelocidad,
    unidad: "seg"
  },
  {
    tipo: "resistencia",
    imagen: resistencia,
    icono: iconResistencia,
    unidad: "min"
  },
  {
    tipo: "salto",
    imagen: salto,
    icono: iconSalto,
    unidad: "cm"
  }
];

// Componente que muestra las métricas verificadas del usuario
const Verificadas = ({
  detailUser,     // Datos completos del usuario
  metricaClick    // Función para manejar clicks en las métricas
}) => {
  return (
    <div className={styles.verificadas}>
      <h2 className={styles.seccionName}>MÉTRICAS VERIFICADAS</h2>

      {/* Mapeo de las métricas configuradas */}
      {METRICAS_CONFIG.map((metrica, index) => (
        <div
          key={index}
          className={styles.contMetricas}
          onClick={() => metricaClick(metrica.tipo)}
        >
          {/* Tarjeta de métrica individual */}
          <div className={styles.metrica}>
            <div className={styles.contNum}>
              {/* Nombre de la métrica */}
              <h3 className={styles.metricaName}>
                {detailUser.Metricas[index]?.nombre.toUpperCase()}
              </h3>
              {/* Valor y unidad */}
              <div className={styles.contUnidad}>
                <p className={styles.num}>
                  {filterMetricas(detailUser, metrica.tipo)[0].valor}
                </p>
                <span className={styles.unidad}>{metrica.unidad}</span>
              </div>
            </div>
            {/* Gráficas e iconos */}
            <img className={styles.grafica} src={metrica.imagen} alt="" />
            <img className={styles.grafica} src={metrica.icono} alt="" />
          </div>
        </div>
      ))}
    </div>
  );
};

export default Verificadas;
