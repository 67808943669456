import React from 'react'
import styles from "../User.module.css"
import { Radar } from "react-chartjs-2"
import RenderCalificaciones from "./RenderCalificaciones"

const Metricas = ({
    user,
    editMetricas,
    editM,
    Guardar,
    valores,
    handleChange
}) => {
  return (
    <div>
      {!editMetricas ? (
        <div className={styles.metricas}>
              <Radar
                className={styles.radar}
                data={{
                  labels: [
                    "PASES CORTOS",
                    "PASES LARGOS",
                    "CABECEO",
                    "REGATE",
                    "DEFENSA",
                    "TIROS",
                  ],
                  datasets: [
                    {
                      label: "HABILIDADES",
                      data: [
                        user.paseCorto,
                        user.paseLargo,
                        user.cabeceo,
                        user.regate,
                        user.defensa,
                        user.tiros,
                      ],
                      fill: true,
                      backgroundColor: "rgba(228, 253, 63, 0.5)",
                      borderColor: "rgb(228, 253, 63)",
                    },
                  ],
                }}
                options={{
                  maintainAspectRatio: true,
                  responsive: true,
                  width: 300,
                  height: 300,
                  scales: {
                    r: {
                      min: 0,
                      max: 5,
                      ticks: {
                        display: false,
                      },
                      pointLabels: {
                        color: "rgb(255, 255, 255)",
                      },
                    },
                  },
                  plugins: {
                    legend: {
                      labels: {
                        color: "white",
                      },
                    },
                  },
                }}
              />
        </div>
      ) : (
        <div className={styles.metricas}>
          <h3 className={styles.seccion}>METRICAS PERSONALES</h3>
          {Object.entries(valores).map(([campo, valor]) => (
            <div key={campo} className={styles.contCalificacion}>
              <h2 className={styles.selectOpcM}>{campo.toUpperCase()}</h2>
              {campo === "paseCorto" && (
                <p className={styles.textInfoM}>
                  Elige qué tan efectivo eres en el pase corto, considerando que
                  Lionel Andrés Messi es el mejor.
                </p>
              )}
              {campo === "paseLargo" && (
                <p className={styles.textInfoM}>
                  Elige qué tan efectivo eres en el pase largo, considerando que
                  Kevin de Bruyne es el más efectivo.
                </p>
              )}
              {campo === "cabeceo" && (
                <p className={styles.textInfoM}>
                  Elige qué tan efectivo eres en el cabeceo, considerando que
                  Cristiano Ronaldo el mejor.
                </p>
              )}
              {campo === "regate" && (
                <p className={styles.textInfoM}>
                  Elige qué tan efectivo eres en el regate, considerando que
                  Lionel Andrés Messi el mejor.
                </p>
              )}
              {campo === "defensa" && (
                <p className={styles.textInfoM}>
                  Elige qué tan efectivo eres defendiendo, considerando que
                  Virgil van Dijk es el más efectivo.
                </p>
              )}
              {campo === "tiros" && (
                <p className={styles.textInfoM}>
                  Elige qué tan efectivo eres en el tiro a portería,
                  considerando que Cristiano Ronaldo Messi es el mejor.
                </p>
              )}
              <div className={styles.contCali}>
                {RenderCalificaciones(campo, handleChange, valores)}
              </div>
            </div>
          ))}
          <div className={styles.cont}>
            <button className={styles.btnRegresar} onClick={editM}>
              Cancelar
            </button>
            <button className={styles.btnContinuar} onClick={Guardar}>
              Guardar
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default Metricas
