import React, { memo } from "react";
import style from "../ModalEntrenamiento.module.css";
import { Line } from "react-chartjs-2";

const ContenidoMetrica = ({
    selectedDate,
    tiempoDia,
    tiempoMesEntrenamiento,
    tiempoMesJugado,
    golesMes,
    rangoFechas,
    data,
    opciones,
    handleRangoChange,
    handleClick,
    mensaje,
    setRangoFechas,
    isRangeView,
    setIsRangeView,
    resetToDaily,
    handlerClickCerrar
}) => {
  return (
    <div className={style.metricasCont}>
      <p
        className={style.btnCerrar}
        onClick={handlerClickCerrar}
      >
        X
      </p>
      <p className={style.title}>
        {isRangeView 
          ? `Estadísticas del ${rangoFechas.inicio.toLocaleDateString("es-ES")} al ${rangoFechas.fin.toLocaleDateString("es-ES")}`
          : `Estadísticas del: ${selectedDate.toLocaleDateString("es-ES", {
              day: "numeric",
              year: "numeric",
              month: "long",
            })}`
        }
      </p>
      <p className={style.subTitle}>MINUTOS ENTRENADOS</p>
      <div className={style.contMetricaDia}>
        <p className={style.minutos}>{tiempoDia}</p>
        <span className={style.unidad}>minutos</span>
      </div>
      <p className={style.title}>Estadísticas</p>
      <EstadisticasMes 
        tiempoMesEntrenamiento={tiempoMesEntrenamiento} 
        tiempoMesJugado={tiempoMesJugado} 
        golesMes={golesMes} 
      />
      <p className={style.subTitle}>ENTRENAMIENTO</p>
      <div className={style.rangoFechas}>
        <div className={style.fechaInput}>
          <label>Desde:</label>
          <input
            type="date"
            value={rangoFechas.inicio.toISOString().split("T")[0]}
            onChange={(e) => {
              handleRangoChange("inicio", e.target.value);
              setIsRangeView(true);
            }}
            max={new Date().toISOString().split("T")[0]}
          />
        </div>

        <div className={style.fechaInput}>
          <label>Hasta:</label>
          <input
            type="date"
            value={
              rangoFechas.fin instanceof Date && !isNaN(rangoFechas.fin)
                ? rangoFechas.fin.toISOString().split("T")[0]
                : new Date().toISOString().split("T")[0]
            }
            onChange={(e) => {
              handleRangoChange("fin", e.target.value);
              setIsRangeView(true);
            }}
            min={rangoFechas.inicio.toISOString().split("T")[0]}
            max={new Date().toISOString().split("T")[0]}
          />
        </div>
      </div>
      <div className={style.contGrafica}>
        {data.labels.length > 0 && <Line data={data} options={opciones} />}
      </div>
      <button 
        className={style.btnAgregar} 
        onClick={() => handleClick()}
        disabled={isRangeView}
      >
        Agregar métricas del día
      </button>
      <span className={style.mensaje}>{mensaje}</span>
    </div>
  );
};

const EstadisticasMes = memo(({ 
  tiempoMesEntrenamiento, 
  tiempoMesJugado, 
  golesMes 
}) => {
  return (
    <div className={style.contMetricaMes}>
      <MetricaItem titulo="MINUTOS ENTRENADOS" valor={tiempoMesEntrenamiento} />
      <MetricaItem titulo="MINUTOS JUGADOS" valor={tiempoMesJugado} />
      <MetricaItem titulo="GOLES" valor={golesMes} unidad="goles" />
    </div>
  );
});

const MetricaItem = ({ titulo, valor, unidad = "minutos" }) => (
  <div className={style.contMetrica}>
    <p className={style.subTitle}>{titulo}</p>
    <div className={style.contMetricaDia}>
      <p className={style.minutos}>{valor}</p>
      <span className={style.unidad}>{unidad}</span>
    </div>
  </div>
);

export default ContenidoMetrica;
