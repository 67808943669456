import { useEffect, useState } from "react";
import style from "./ModalMetrica.module.css";
import axios from "axios";
import { URL } from "../../link";
import { getUser } from "../../actions";
import { useDispatch } from "react-redux";
import { filterMetricas } from "./utils/filterMetricas";
import Grafica from "./Componentes/Grafica";
import Historico from "./Componentes/Historico";

/**
 * Componente ModalMetrica
 * Este modal muestra métricas comparativas e históricas del usuario.
 * Permite alternar entre visualizaciones gráficas y datos históricos.
 *
 * @param {Object} props - Propiedades del componente.
 * @param {Function} props.setMetricaBool - Función para cerrar el modal.
 * @param {string} props.metricaV - Nombre de la métrica seleccionada.
 */
const ModalMetrica = ({ setMetricaBool, metricaV }) => {
  // Obtiene la información del usuario almacenada en localStorage
  const user = JSON.parse(localStorage.getItem("dataUser"));
  const dispach = useDispatch();

  // Estado para controlar las métricas y vistas del modal
  const [bool, setBool] = useState(false);
  const [firstSixPosicion, setFirstSixPosicion] = useState(user.Metricas);
  const [arrayComparativo, setArrayComparativo] = useState([]);
  const [comparativoBool, setComparativoBool] = useState(true);
  const [historicoBool, setHistoricoBool] = useState(false);

  /**
   * Maneja el clic para activar la vista comparativa.
   */
  const onClickComparativo = () => {
    setComparativoBool(true);
    setHistoricoBool(false);
    setBool(false);
  };

  /**
   * Maneja el clic para activar la vista histórica.
   */
  const onClickHistorico = () => {
    setComparativoBool(false);
    setHistoricoBool(true);
    setBool(true);
  };

  /**
   * Cierra el modal y restablece la posición de desplazamiento.
   */
  const onClickCerrar = () => {
    setMetricaBool(false);
    window.scrollTo(0, 0);
  };

  /**
   * Obtiene los datos comparativos de la métrica seleccionada desde la API.
   */
  const getComparativo = async () => {
    try {
      const response = await axios.get(`${URL}/metrica/comparativo/${metricaV}`);
      setArrayComparativo(response.data);
    } catch (error) {
      console.error("Error al obtener las métricas:", error.message);
    }
  };

  // Carga los datos comparativos y filtra métricas al montar el componente
  useEffect(() => {
    if (arrayComparativo.length === 0) {
      getComparativo();
      getFilterMetricas();
    }
  }, []);

  // Obtiene la información actualizada del usuario al montar el componente
  useEffect(() => {
    dispach(getUser(user.id));
    console.log("me actualizo");
  }, []);

  /**
   * Obtiene y filtra las métricas del usuario según la métrica seleccionada.
   */
  const getFilterMetricas = async () => {
    try {
      const response = await axios.get(`${URL}/metrica/user/${user.id}`);
      const metricasFiltradas = response.data.Metricas.filter(
        (metrica) => metrica.nombre === metricaV
      );
      const metricasOrdenadas = metricasFiltradas
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        .slice(0, 6);
      setFirstSixPosicion(metricasOrdenadas);
      // Se podría incluir el dispatch getUser para actualizar las métricas
    } catch (error) {
      console.error("Error al obtener las métricas:", error.message);
    }
  };

  return (
    <div className={style.conteiner}>
      <p className={style.titleMAnd} onClick={onClickCerrar}>
        cerrar
      </p>
      <div className={!bool ? style.modalContentAnd : style.modalContent}>
        <Grafica
          user={user}
          metricaV={metricaV}
          bool={bool}
          comparativoBool={comparativoBool}
          firstSixPosicion={firstSixPosicion}
          getComparativo={getComparativo}
          getFilterMetricas={getFilterMetricas}
        />
        <Historico
          user={user}
          metricaV={metricaV}
          bool={bool}
          comparativoBool={comparativoBool}
          arrayComparativo={arrayComparativo}
          filterMetricas={filterMetricas}
          onClickComparativo={onClickComparativo}
          onClickHistorico={onClickHistorico}
          historicoBool={historicoBool}
          setMetricaBool={setMetricaBool}
        />
      </div>
    </div>
  );
};

export default ModalMetrica;
