export function generaDataConstancia(entrenamientosByDate, labels) {
    if (!entrenamientosByDate || entrenamientosByDate.length === 0) {
      return {
        label: "Constancia",
        data: labels.map(() => 0),
        borderColor: "rgba(255, 193, 7, 1)",
        backgroundColor: "rgba(255, 193, 7, 0)",
        fill: false,
        tension: 0.4,
        pointRadius: 0,
        borderWidth: 2,
        spanGaps: true
      };
    }
  
    // Ordenar entrenamientos por fecha
    const sortedEntrenamientos = [...entrenamientosByDate].sort(
      (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
    );
  
    const constanciaPorFecha = {};
  
    // Procesar cada fecha del rango
    labels.forEach(label => {
      const [dia, mes, año] = label.split('/');
      const fechaActual = new Date(`20${año}-${mes}-${dia}`);
      
      // Filtrar entrenamientos hasta esta fecha
      const entrenamientosHastaFecha = sortedEntrenamientos.filter(
        e => new Date(e.createdAt) <= fechaActual
      );
  
      if (entrenamientosHastaFecha.length === 0) {
        constanciaPorFecha[label] = 0;
        return;
      }
  
      // Calcular días consecutivos
      const diasConsecutivos = calcularDiasConsecutivos(entrenamientosHastaFecha, fechaActual);
  
      // Calcular frecuencia de entrenamientos en los últimos 30 días
      const ultimoMes = entrenamientosHastaFecha.filter(e => {
        const fechaEntrenamiento = new Date(e.createdAt);
        const diferenciaDias = Math.floor(
          (fechaActual - fechaEntrenamiento) / (1000 * 60 * 60 * 24)
        );
        return diferenciaDias <= 30;
      });
  
      // Calcular puntuación de constancia (0-100)
      const frecuenciaIdeal = 30; // Un entrenamiento diario sería ideal
      const frecuenciaReal = ultimoMes.length;
      const puntuacionFrecuencia = Math.min((frecuenciaReal / frecuenciaIdeal) * 100, 100);
      
      // Combinar días consecutivos con frecuencia
      const puntuacionConsecutivos = Math.min((diasConsecutivos / 7) * 100, 100);
      
      // Peso: 40% días consecutivos, 60% frecuencia mensual
      constanciaPorFecha[label] = Math.round(
        (puntuacionConsecutivos * 0.4) + (puntuacionFrecuencia * 0.6)
      );
    });
  
    // Crear array final
    const valoresConstancia = labels.map(
      label => constanciaPorFecha[label] || 0
    );
  
    return {
      label: "Constancia",
      data: valoresConstancia,
      borderColor: "rgba(12, 177, 234, 1)",
      backgroundColor: "rgba(12, 177, 234, 1)",
      fill: false,
      tension: 0.4,
      pointRadius: 0,
      borderWidth: 2,
      spanGaps: true,
      tooltip: {
        callbacks: {
          label: (context) => {
            return `Constancia: ${context.raw}%`;
          }
        }
      }
    };
  }
  
  function calcularDiasConsecutivos(entrenamientos, fechaActual) {
    let diasConsecutivos = 1;
    let fechaAnterior = fechaActual;
  
    for (let i = 0; i < entrenamientos.length; i++) {
      const fechaEntrenamiento = new Date(entrenamientos[i].createdAt);
      const diferenciaDias = Math.floor(
        (fechaAnterior - fechaEntrenamiento) / (1000 * 60 * 60 * 24)
      );
  
      if (diferenciaDias === 1) {
        diasConsecutivos++;
        fechaAnterior = fechaEntrenamiento;
      } else if (diferenciaDias !== 0) {
        break;
      }
    }
  
    return diasConsecutivos;
  }