import React from "react";
import style from "../ModalMetrica.module.css";
import medallaOne from "../../../assets/medallaOne.svg";
import medallaTwo from "../../../assets/medallaTwo.svg";
import medallaThree from "../../../assets/MedallaThree.svg";
import { calcularEdad } from "../utils/calcularEdad";
import { getUnidad } from "../utils/getChartOptions";
import { fechaFormateada } from "../utils/metricsUtils";

/**
 * Componente que representa una fila en la tabla de posiciones de métricas.
 * @param {Object} props
 * @param {number} props.index - Índice de la posición en la lista.
 * @param {Object} props.data - Datos del usuario o métrica a mostrar.
 * @param {boolean} [props.showMedals=false] - Indica si se deben mostrar medallas.
 * @param {boolean} [props.isHistorico=false] - Indica si los datos son históricos.
 * @param {string} props.metricaV - Tipo de métrica que se está mostrando.
 */
const PosicionRow = ({ index, data, showMedals = false, isHistorico = false, metricaV }) => {
  // Renderiza la medalla según la posición
  const renderMedal = () => {
    if (!showMedals || index > 2) return null;
    
    const medals = {
      0: { src: medallaOne, alt: "Medalla oro" },
      1: { src: medallaTwo, alt: "Medalla plata" },
      2: { src: medallaThree, alt: "Medalla bronce" }
    };

    return medals[index] && (
      <img 
        className={style.iconM} 
        src={medals[index].src} 
        alt={medals[index].alt} 
      />
    );
  };

  // Obtiene la información del usuario o métrica a mostrar
  const getUserInfo = () => {
    if (isHistorico) {
      return {
        foto: data.foto,
        nombre: data.nombre,
        info: fechaFormateada(data.createdAt)
      };
    }
    
    const usuario = data.Usuarios[0];
    return {
      foto: usuario.foto,
      nombre: usuario.nombre,
      info: `${usuario.posicion} - ${calcularEdad(usuario.dia, usuario.mes, usuario.anio)} años`
    };
  };

  const userInfo = getUserInfo();

  return (
    <div className={index % 2 === 1 ? style.posicionP : style.posicion}>
      {renderMedal()}
      
      <div className={style.contInfoUser}>
        {(index > 2 || !showMedals) && (
          <p className={style.numList}>
            {index === 0 ? "Actual" : index + 1}
          </p>
        )}
        <img
          className={style.userFoto}
          src={userInfo.foto}
          alt="Foto de perfil"
        />
        <div className={style.infoUser}>
          <h3 className={style.nameUser}>{userInfo.nombre}</h3>
          <p className={style.info}>{userInfo.info}</p>
        </div>
      </div>
      <div className={style.contDataP}>
        <h1 className={isHistorico ? style.data : style.dataL}>
          {data.valor}
        </h1>
        <p className={style.unidad}>{getUnidad(metricaV)}</p>
      </div>
    </div>
  );
};

/**
 * Componente que muestra un historial o comparativa de métricas.
 * @param {Object} props
 * @param {Object} props.user - Datos del usuario.
 * @param {string} props.metricaV - Tipo de métrica a mostrar.
 * @param {boolean} props.comparativoBool - Indica si se muestra la comparativa.
 * @param {boolean} props.historicoBool - Indica si se muestra el historial.
 * @param {Function} props.onClickComparativo - Maneja el cambio a vista comparativa.
 * @param {Function} props.onClickHistorico - Maneja el cambio a vista histórica.
 * @param {Array} props.arrayComparativo - Datos para la vista comparativa.
 * @param {Function} props.filterMetricas - Función para filtrar métricas.
 * @param {Function} props.setMetricaBool - Función para cerrar la métrica.
 */
const Historico = ({
  user,
  metricaV,
  comparativoBool,
  onClickComparativo,
  onClickHistorico,
  historicoBool,
  arrayComparativo,
  filterMetricas,
  setMetricaBool,
}) => {
  // Ordena los datos para la comparativa
  const sortedComparativo = React.useMemo(() => {
    if (!arrayComparativo) return [];
    return [...arrayComparativo].sort((a, b) => {
      if (metricaV === "velocidad" || metricaV === "resistencia") {
        return a.valor - b.valor;
      }
      return b.valor - a.valor;
    });
  }, [arrayComparativo, metricaV]);

  // Renderiza los botones de selección de vista
  const renderButton = (isComparativo) => {
    const isSelected = isComparativo ? comparativoBool : historicoBool;
    const onClick = isComparativo ? onClickComparativo : onClickHistorico;
    const text = isComparativo ? "Comparativa" : "Histórico";

    return (
      <div
        className={isSelected ? style.btnSelected : style.btn}
        onClick={onClick}
      >
        <p className={isSelected ? style.btnTxtSelected : style.btnTxt}>
          {text}
        </p>
      </div>
    );
  };

  return (
    <div className={style.contHistorico}>
      <p className={style.titleM} onClick={() => setMetricaBool(false)}>
        X
      </p>

      <div className={style.contSubHistorico}>
        <div className={style.contMenu}>
          {renderButton(true)}
          {renderButton(false)}
        </div>

        <div className={style.contHistorial}>
          {comparativoBool ? (
            sortedComparativo.map((posicion, index) => (
              <PosicionRow
                key={index}
                index={index}
                data={posicion}
                showMedals={true}
                isHistorico={false}
                metricaV={metricaV}
              />
            ))
          ) : (
            filterMetricas(user, metricaV).map((metric, index) => (
              <PosicionRow
                key={index}
                index={index}
                data={{...metric, foto: user.foto, nombre: user.nombre}}
                showMedals={false}
                isHistorico={true}
                metricaV={metricaV}
              />
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(Historico);