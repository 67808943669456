import React from 'react'
import { useDispatch } from 'react-redux'
import { getUser } from '../../../actions'
import { URL } from "../../../link";
import { useState, useRef } from "react";
import axios from "axios";
import subirImg from "../../../assets/subirVideo.png";
import { getUnidad } from "../utils/getChartOptions";
import style from "../ModalMetrica.module.css";

const SubirMetrica = ({
    user,
    metricaV,
    getComparativo,
    getFilterMetricas,
    editBool,
    setEditBool,
}) => {
    const dispach = useDispatch()
    const [metrica, setMetrica] = useState({})
    const [upLoadVideo, setUpLoadVideo] = useState("")
    const [loadingVideo, setLoadingVideo] = useState(false);
    const fileInputVideoRef = useRef(null);
    const [videoLoadingInProgress, setVideoLoadingInProgress] = useState(false);

    const preset_key = "videoUpload";
    const cloud_name = "dvwtgluwc";
    const cloudinaryUrlVideo = `https://api.cloudinary.com/v1_1/${cloud_name}/video/upload`;

    const handleVideoUpload = () => {
        fileInputVideoRef.current.click();
    };

    const uploadVideo = async (file) => {
        if (file.type.startsWith('video/')) {
            setVideoLoadingInProgress(true);
            setLoadingVideo(true);

            try {
                const video = document.createElement('video');
                video.preload = 'metadata';

                video.onloadedmetadata = async function () {
                    window.URL.revokeObjectURL(video.src);
                    const duration = video.duration;

                    if (duration <= 1200) {
                        const data = new FormData();
                        data.append('file', file);
                        data.append('upload_preset', preset_key);

                        try {
                            const response = await axios.post(cloudinaryUrlVideo, data);
                            setUpLoadVideo(response.data.public_id);
                            setVideoLoadingInProgress(false);
                        } catch (error) {
                            console.log(error);
                            setVideoLoadingInProgress(false);
                            setLoadingVideo(false);
                        }
                    } else {
                        console.log("El video supera los 35 segundos");
                        setVideoLoadingInProgress(false);
                        setLoadingVideo(false);
                    }
                }

                video.src = window.URL.createObjectURL(file);
            } catch (error) {
                console.log(error);
                setVideoLoadingInProgress(false);
                setLoadingVideo(false);
            } finally {
                setLoadingVideo(false);
            }
        } else {
            console.log("El archivo no es un video");
        }
    };

    const subirMetrica = async () => {
        try {
            // Aceptar solo números enteros o decimales positivos
            if (typeof metrica !== 'string' || !metrica.trim().match(/^\d+(\.\d+)?$/)) {
                console.log("No es un número válido");
            } else {
                const response = await axios.post(`${URL}/metrica/nueva`, {
                    usuarioId: user.id,
                    nombre: metricaV,
                    valor: metrica,
                    video: upLoadVideo
                })
                localStorage.setItem("dataUser", JSON.stringify(response.data))
                getComparativo();
                getFilterMetricas();
                dispach(getUser(user.id))
                setEditBool(true)
            }

        } catch (error) {
            console.error('Error al subir la métrica:', error.message);
        }
    }

  return (
    <div className={style.contEdit}>
        {editBool || (
          <div className={style.contBtnG}>
            <h2 className={style.titEdit}>SUBIR NUEVA METRICA</h2>
            <p className={style.avisoG}>Nueva metrica de {metricaV}</p>
            <div className={style.contEditBtn}>
              {metricaV === "resistencia" && (
                <input
                  className={style.inpG}
                  type="text"
                  name=""
                  id=""
                  placeholder={`Tu tiempo en 3km`}
                  onChange={(e) => setMetrica(e.target.value)}
                />
              )}
              {metricaV === "fuerza" && (
                <input
                  className={style.inpG}
                  type="text"
                  name=""
                  id=""
                  placeholder={`Levantado en pierna`}
                  onChange={(e) => setMetrica(e.target.value)}
                />
              )}
              {metricaV === "velocidad" && (
                <input
                  className={style.inpG}
                  type="text"
                  name=""
                  id=""
                  placeholder={`Tu tiempo en 50 mts`}
                  onChange={(e) => setMetrica(e.target.value)}
                />
              )}
              {metricaV === "salto" && (
                <input
                  className={style.inpG}
                  type="text"
                  name=""
                  id=""
                  placeholder={`Altura de tu salto`}
                  onChange={(e) => setMetrica(e.target.value)}
                />
              )}
              <span className={style.txtUnidad}>{getUnidad(metricaV)}</span>
              <button className={style.btnG} onClick={subirMetrica}>
                Agregar
              </button>
            </div>
            <div className={style.contAviso}>
              <p className={style.avisoH}>
                Agrega un video de hasta 30seg con la evidencia de este logro.
              </p>
            </div>
            <input
              type="file"
              name="video"
              onChange={(e) => uploadVideo(e.target.files[0])}
              ref={fileInputVideoRef}
              className={style.inputext}
              accept="video/*"
              disabled={loadingVideo || videoLoadingInProgress}
            />
            <img
              className={style.subir}
              src={subirImg}
              alt=""
              onClick={handleVideoUpload}
            />
            {upLoadVideo && (
              <p className={style.avisoH}>Video cargado con exito.</p>
            )}
          </div>
        )}
      </div>
  )
}

export default SubirMetrica
