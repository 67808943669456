export function generaDataEntrenamiento(
  entrenamientosByDate,
  setDataEntrenamiento,
  labels
) {
  const defaultDatasets = [
    {
      label: "Entrenamiento",
      data: labels.map(() => 0),
      borderColor: "rgba(76, 60, 207, 1)",
      backgroundColor: "rgba(76, 60, 207, 0)",
      fill: false,
      stepped: true,
      tension: 0,
      pointRadius: 0,
      spanGaps: true,
      pointBackgroundColor: "rgba(76, 60, 207, 1)",
      tiemposYTipos: labels.map(() => ({ tiempo: 0, tipo: "" })),
      tooltip: {
        callbacks: {
          label: (context) => {
            const dataset = context.dataset;
            const index = context.dataIndex;
            const tiempo = dataset.tiemposYTipos[index]?.tiempo || 0;
            const tipo = dataset.tiemposYTipos[index]?.tipo || "Sin actividad";
            return [`${tipo}`, `Tiempo: ${tiempo} min`];
          },
        },
      },
    },
    {
      type: "bar",
      label: "Goles",
      data: labels.map(() => 0),
      borderColor: "rgba(63, 253, 228, 1)",
      backgroundColor: "rgba(63, 253, 228, 0.6)",
      fill: true,
      barThickness: 8,
      tooltip: {
        callbacks: {
          label: (context) => {
            const value = context.raw || 0;
            return `Goles: ${value / 10}`;
          },
        },
      },
    },
  ];

  if (!entrenamientosByDate || entrenamientosByDate.length === 0) {
    setDataEntrenamiento(defaultDatasets);
    return;
  }
  // Ordenar los entrenamientos por fecha
  const sortedEntrenamientos = [...entrenamientosByDate].sort(
    (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
  );

  const valoresEntrenamientoPorFecha = {};
  const valoresGolesPorFecha = {};
  const tiemposYTiposPorFecha = {};

  // Procesar cada entrenamiento y asignarlo a su fecha específica
  sortedEntrenamientos.forEach((entrenamiento) => {
    const fecha = new Date(entrenamiento.createdAt);
    const dia = fecha.getDate().toString().padStart(2, "0");
    const mes = (fecha.getMonth() + 1).toString().padStart(2, "0");
    const año = fecha.getFullYear().toString().slice(-2);
    const fechaKey = `${dia}/${mes}/${año}`;

    // Calcular el puntaje base
    const puntajeBase = calcularPuntajeEntrenamiento(entrenamiento);

    // Calcular días consecutivos hasta esta fecha
    const entrenamientosHastaFecha = sortedEntrenamientos.filter(
      (e) => new Date(e.createdAt) <= fecha
    );
    const diasConsecutivos = calcularDiasConsecutivos(
      entrenamientosHastaFecha,
      entrenamiento.createdAt
    );
    const multiplicadorConstancia = Math.max(
      0.3,
      Math.min(diasConsecutivos / 30, 1)
    );

    // Calcular puntaje final
    valoresEntrenamientoPorFecha[fechaKey] = Math.round(
      puntajeBase * multiplicadorConstancia
    );

    // Guardar goles si es un partido
    if (entrenamiento.tipo === "Partido" && entrenamiento.goles) {
      valoresGolesPorFecha[fechaKey] = Math.min(entrenamiento.goles, 3) * 10;
    }

    // Guardar tiempo y tipo
    tiemposYTiposPorFecha[fechaKey] = {
      tiempo: entrenamiento.tiempo,
      tipo: entrenamiento.tipo,
    };
  });

  // Crear arrays finales solo con las fechas exactas de los entrenamientos
  const valoresEntrenamiento = labels.map(
    (label) => valoresEntrenamientoPorFecha[label] || 0
  );

  const valoresGoles = labels.map((label) => valoresGolesPorFecha[label] || 0);

  const tiemposYTipos = labels.map(
    (label) =>
      tiemposYTiposPorFecha[label] || { tiempo: 0, tipo: "Sin actividad" }
  );

  // Actualizar datasets
  defaultDatasets[0].data = valoresEntrenamiento;
  defaultDatasets[0].tiemposYTipos = tiemposYTipos;
  defaultDatasets[1].data = valoresGoles;

  setDataEntrenamiento(defaultDatasets);
}

function calcularPuntajeEntrenamiento(entrenamiento) {
  if (entrenamiento.tipo === "Partido") {
    const tiempoMaximo = 90;
    const golesMaximo = 3;

    const puntajeTiempo =
      (Math.min(entrenamiento.tiempo, tiempoMaximo) / tiempoMaximo) * 40 * 0.7;
    const puntajeGoles =
      (Math.min(entrenamiento.goles || 0, golesMaximo) / golesMaximo) *
      40 *
      0.3;

    return puntajeTiempo + puntajeGoles;
  }

  if (entrenamiento.tipo === "Entrenamiento") {
    const tiempoMaximo = 120;
    return (Math.min(entrenamiento.tiempo, tiempoMaximo) / tiempoMaximo) * 40;
  }

  return 0;
}

function calcularDiasConsecutivos(entrenamientos, fechaActual) {
  let diasConsecutivos = 1;
  let fechaAnterior = new Date(fechaActual);

  for (let i = 0; i < entrenamientos.length; i++) {
    const fechaEntrenamiento = new Date(entrenamientos[i].createdAt);
    const diferenciaDias = Math.floor(
      (fechaAnterior - fechaEntrenamiento) / (1000 * 60 * 60 * 24)
    );

    if (diferenciaDias === 1) {
      diasConsecutivos++;
      fechaAnterior = fechaEntrenamiento;
    } else if (diferenciaDias !== 0) {
      break;
    }
  }

  return diasConsecutivos;
}
