export  const generateDays = (date, entrenamientos, setDaysInMonth) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const days = [];
    const daysInCurrentMonth = new Date(year, month + 1, 0).getDate();

    for (let i = 1; i <= daysInCurrentMonth; i++) {
      const currentDay = new Date(year, month, i);
      currentDay.setHours(0, 0, 0, 0); // Normalizar la fecha a medianoche

      // Buscar si hay un entrenamiento en esta fecha
      const entrenamiento = entrenamientos.find((ent) => {
        const entrenamientoDate = new Date(ent.createdAt);
        entrenamientoDate.setHours(0, 0, 0, 0); // Normalizar fecha del entrenamiento
        return entrenamientoDate.getTime() === currentDay.getTime();
      });

      if (currentDay < today) {
        days.push({
          date: currentDay,
          status: "past",
          entrenamiento: entrenamiento || null,
        });
      } else if (currentDay.getTime() === today.getTime()) {
        days.push({
          date: currentDay,
          status: "current",
          entrenamiento: entrenamiento || null,
        });
        console.log("Día actual encontrado:", currentDay);
      } else {
        days.push({ date: currentDay, status: "future" });
      }
    }

    setDaysInMonth(days);
  };