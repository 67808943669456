import React, { useEffect, useState, useCallback, memo } from "react";
import styles from "./Home.module.css";
import { useDispatch, useSelector } from "react-redux";
import Stadistics from "../Stadistics/Stadistics";
import Feed from "../Feed/Feed";
import User from "../User/User.jsx";
import ModalNotificaciones from "../ModalNotificaciones/ModalNotificaciones.jsx";
import ModalUser from "../ModalUser/ModalUser.jsx";
import ModalMetrica from "../ModalMetrica/ModalMetrica.jsx";
import Otro from "../Otro/Otro.jsx";
import Listas from "../Listas/Listas.jsx";
import NavSecond from "../NavSecond/NavSecond.jsx";
import { getListas, getEntrenamientos } from "../../actions/index.js";
import ModalEntrenamiento from "../ModalEntrenamiento/Modalentrenamiento.jsx";

const Home = ({
  user,
  inVisitor,
  setModalEntrenamientoBool,
  modalEntrenamientoBool,
  entrenamientos,
  notificacionesBool,
  desactivarNotificacion,
  userBool,
  logOut,
  dispacherPublicaciones,
  inAmigos,
  clickMessage,
  inEdit,
  allJugadores,
  listas,
  dispatcherAmigos,
  noticias,
  publicacionView,
  seguir,
}) => {
  const dispatch = useDispatch();
  const publicaciones = useSelector((state) => state.allPublicaciones);
  const userPublicaciones = useSelector((state) => state.userPubliaciones);

  const [metricaBool, setMetricaBool] = useState(false);
  const [metricaValor, setMetricaValor] = useState("");
  const [bool, setBool] = useState(true);
  const [threeBool, setTrheeBool] = useState(false);
  const [boolTwo, setBoolTwo] = useState(false);
  const [isVisitorBool, setIsVisitorBool] = useState(false);
  const [navSecondActive, setNavSecondActive] = useState(true);

  useEffect(() => {
    dispacherPublicaciones();
    dispatch(getListas(user.id));
    dispatch(getEntrenamientos(user.id));
  }, [user.id]);

  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (
        (notificacionesBool && !event.target.closest(".modal-notificaciones")) ||
        (userBool && !event.target.closest(".modal-user"))
      ) {
        desactivarNotificacion();
      }
    };

    document.addEventListener("click", handleDocumentClick);
    return () => document.removeEventListener("click", handleDocumentClick);
  }, [notificacionesBool, userBool]);

  const metricaClick = useCallback((metricaV) => {
    setMetricaBool(true);
    setMetricaValor(metricaV);
    window.scrollTo(0, 0);
    setNavSecondActive(false);
  }, []);

  const clickBool = useCallback(() => {
    setBool(true);
    setTrheeBool(false);
    setBoolTwo(false);
  }, []);

  const clickBoolTwo = useCallback(() => {
    setTrheeBool(false);
    setBool(false);
    setBoolTwo(true);
  }, []);

  const clickBoolThree = useCallback(() => {
    setBool(false);
    setTrheeBool(true);
    setBoolTwo(false);
  }, []);

  const containerClass = `${styles.conteiner} ${boolTwo ? styles.socialActive : ""}`;

  return (
    <div className={containerClass}>
      {user.rol === "Jugador" ? (
        <div className={bool ? styles.partOne : styles.partDesactive}>
          <Stadistics
            detailUser={user}
            metricaClick={metricaClick}
            setModalEntrenamientoBool={setModalEntrenamientoBool}
            entrenamientos={entrenamientos}
            setNavSecondActive={setNavSecondActive}
          />
        </div>
      ) : (
        <div className={bool ? styles.partOne : styles.partDesactive}>
          <Otro
            inAmigos={inAmigos}
            user={user}
            isVisitorBool={isVisitorBool}
          />
        </div>
      )}

      {user.rol === "Jugador" ? (
        <div className={!boolTwo ? styles.partTwo : styles.partDesactive}>
          <User
            user={user}
            entrenamientos={entrenamientos}
          />
        </div>
      ) : (
        <div className={threeBool ? styles.partThree : styles.partDesactive}>
          <Listas
            allJugadores={allJugadores}
            listas={listas}
            dispatcherAmigos={dispatcherAmigos}
            inVisitor={inVisitor}
          />
        </div>
      )}

      <div className={boolTwo ? styles.partThree : styles.partDesactive}>
        <Feed
          user={user}
          publicaciones={publicaciones}
          inVisitor={inVisitor}
          userPublicaciones={userPublicaciones}
          noticias={noticias}
          publicacionView={publicacionView}
          seguir={seguir}
        />
      </div>

      {notificacionesBool && (
        <ModalNotificaciones clickMessage={clickMessage} />
      )}
      
      {userBool && (
        <ModalUser logOut={logOut} inEdit={inEdit} />
      )}
      
      {metricaBool && (
        <ModalMetrica
          setMetricaBool={setMetricaBool}
          metricaV={metricaValor}
          user={user}
        />
      )}
      
      {modalEntrenamientoBool && (
        <ModalEntrenamiento
          entrenamientos={entrenamientos}
          setModalEntrenamientoBool={setModalEntrenamientoBool}
          user={user}
          setNavSecondActive={setNavSecondActive}
        />
      )}

      {navSecondActive && (
        <div className={styles.navSecond}>
          <NavSecond
            bool={bool}
            boolTwo={boolTwo}
            clickBool={clickBool}
            clickBoolTwo={clickBoolTwo}
            clickBoolThree={clickBoolThree}
            user={user}
            threeBool={threeBool}
            isVisitor={false}
          />
        </div>
      )}
    </div>
  );
};

export default memo(Home);