import React, { memo } from "react";
import style from "../ModalEntrenamiento.module.css";
import balonImage from "../../../assets/balonMetrica.svg";
import notIcont from "../../../assets/x21.png";
import backIcon from "../../../assets/isquierda.png";
import nextIcon from "../../../assets/derecha.png";
import PropTypes from 'prop-types';

const Calendario = memo(({
    currentDate,
    handleMonthChange,
    daysInMonth,
    tiempo,
    isCurrentMonth
}) => {
  return (
    <div className={style.calendarioCont}>
      <div className={style.fechaCont}>
        <p className={style.fechaCalendario}>
          {currentDate.toLocaleDateString("es-ES", {
            year: "numeric",
            month: "long",
          })}
        </p>
      </div>
      <div className={style.semana}>
        {["D", "L", "M", "M", "J", "V", "S"].map((dia, index) => (
          <div key={index} className={style.diaSemana}>
            {dia}
          </div>
        ))}
      </div>
      <div className={style.calendario}>
        {Array.from({
          length: new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            1
          ).getDay(),
        }).map((_, index) => (
          <div key={`empty-${index}`} className={style.placeholder}></div>
        ))}
        {daysInMonth.map((day, index) => (
          <DiaCalendario key={index} day={day} tiempo={tiempo} />
        ))}
      </div>

      <div className={style.controlesCont}>
        <img
          className={style.controlIcon}
          src={backIcon}
          alt="back"
          onClick={() => handleMonthChange("prev")}
        />
        <img
          className={`${style.controlIcon} ${isCurrentMonth ? style.disabled : ''}`}
          src={nextIcon}
          alt="next"
          onClick={() => !isCurrentMonth && handleMonthChange("next")}
          style={{ 
            opacity: isCurrentMonth ? 0.5 : 1,
            cursor: isCurrentMonth ? 'not-allowed' : 'pointer'
          }}
        />
      </div>
    </div>
  );
});

const DiaCalendario = memo(({ day, tiempo }) => {
  return (
    <div className={style.dia}>
      <p className={style.diaNum}>{day.date.getDate()}</p>
      <div className={style.contDia}>
        {day.status === "past" || day.status === "current" ? (
          day.entrenamiento ? (
            <div className={style.contCirculo}>
              <p className={style.valorM}>{day.entrenamiento.tiempo}"</p>
              {day.entrenamiento.goles !== null && (
                <img
                  src={balonImage}
                  alt="balon"
                  className={style.balon}
                />
              )}
            </div>
          ) : day.status === "current" ? (
            <div className={style.divCirculoCurrent}>
              <p className={style.valorC}>{tiempo}</p>
            </div>
          ) : (
            <img
              src={notIcont}
              alt="No entreno"
              className={style.iconoNoEntreno}
            />
          )
        ) : (
          <div className={style.divFuture}></div>
        )}
      </div>
    </div>
  );
});

Calendario.propTypes = {
  currentDate: PropTypes.instanceOf(Date).isRequired,
  handleMonthChange: PropTypes.func.isRequired,
  daysInMonth: PropTypes.arrayOf(PropTypes.shape({
    date: PropTypes.instanceOf(Date),
    status: PropTypes.string,
    entrenamiento: PropTypes.object
  })).isRequired,
  tiempo: PropTypes.number,
  isCurrentMonth: PropTypes.bool
};

export default Calendario;
