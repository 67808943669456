export function getDataLineas(data, tipo) {
  if (!data || data.length === 0) return null;

  // Configuración de estilos por tipo
  const configuraciones = {
    noventa: {
      label: "Destacado",
      borderColor: "rgba(60, 98, 207, 1)",
      backgroundColor: "rgba(60, 98, 207, 0)",
    },
    ochenta: {
      label: "Medio",
      borderColor: "rgba(60, 207, 93, 1)",
      backgroundColor: "rgba(60, 207, 93, 0)",
    },
    setenta: {
      label: "Bajo",
      borderColor: "rgba(207, 78, 60, 1)",
      backgroundColor: "rgba(207, 78, 60, 0)",
    }
  };

  // Ordenar los datos por fecha
  const sortedData = [...data].sort(
    (a, b) => new Date(a.fecha) - new Date(b.fecha)
  );

  // Obtener los valores
  const valores = sortedData.map(item => item.valor);

  // Retornar el dataset configurado
  return {
    label: configuraciones[tipo].label,
    data: valores,
    borderColor: configuraciones[tipo].borderColor,
    backgroundColor: configuraciones[tipo].backgroundColor,
    fill: false,
    tension: 0.4,
    pointRadius: 0,
    pointBackgroundColor: configuraciones[tipo].borderColor,
    // Agregar configuración para ocultar tooltips
    tooltip: {
      enabled: false
    }
  };
}