import React from "react";
import styles from "../User.module.css";
const HabilidadesVisitor = ({ user }) => {
  return (
    <div className={styles.habilidades}>
      <div className={styles.contTitle}>
        <h3 className={styles.seccion}>HABILIDADES DESTACADAS</h3>
      </div>
      <div className={styles.contHabilidades}>
        {user.habilidadesSeleccionadas.map((habilidad, index) => (
          <div key={index} className={styles.habi}>
            <p className={styles.txt}>{habilidad}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HabilidadesVisitor;
