export const getUnidad = (metricaV) => {
    if (metricaV === "fuerza") {
        return "kg";
    }
    if (metricaV === "velocidad") {
        return "seg";
    }
    if (metricaV === "resistencia") {
        return "min";
    }
    if (metricaV === "salto") {
        return "cm";
    }
};

export const getChartOptions = (metricaV) => ({
    plugins: {
        legend: {
            display: false
        },
    },
    scales: {
        x: {
            grid: {
                color: "rgba(21, 26, 32, 1)",
                display: false
            },
            ticks: {
                color: "rgba(255, 255, 255, 1)"
            }
        },
        y: {
            grid: {
                color: "rgba(21, 26, 32, 1)"
            },
            ticks: {
                color: "rgba(255, 255, 255, 1)",
                callback: function (value) {
                    return value + " " + getUnidad(metricaV);
                }
            }
        }
    }
});