export  const calcularEdad = (dia, mes, anio) => {
    const fechaNacimiento = new Date(anio, mes - 1, dia);
    const fechaActual = new Date();

    let edad = fechaActual.getFullYear() - fechaNacimiento.getFullYear();

    const mesActual = fechaActual.getMonth() + 1;
    const diaActual = fechaActual.getDate();

    if (mesActual < mes || (mesActual === mes && diaActual < dia)) {
        edad--;
    }

    return edad;
};