export  const getRachaMaxima = (entrenamientos, setRachaMaxima) => {
    const sortedEntrenamientos = entrenamientos
      .map((ent) => ({
        ...ent,

        normalizedDate: new Date(new Date(ent.createdAt).setHours(0, 0, 0, 0)),
      }))
      .sort((a, b) => a.normalizedDate - b.normalizedDate); // Ordenar en orden ascendente
    let maxStreak = 0;
    let currentStreak = 0; // Inicia en 1 porque contamos el primer día como parte de una racha

    for (let i = 1; i < sortedEntrenamientos.length; i++) {
      const prevDate = sortedEntrenamientos[i - 1].normalizedDate;
      const currentDate = sortedEntrenamientos[i].normalizedDate;
      // Si la fecha actual es el día siguiente de la anterior, incrementar la racha actual
      if (
        (currentDate - prevDate) / (1000 * 60 * 60 * 24) ===
        1 // Diferencia de 1 día
      ) {
        currentStreak++;
      } else if (currentDate > prevDate) {
        // Si no son consecutivos, reinicia la racha actual
        maxStreak = Math.max(maxStreak, currentStreak); // Actualiza la racha máxima
        currentStreak = 1; // Reinicia la racha actual
      }
    }
    // Comparar la última racha con la máxima
    maxStreak = Math.max(maxStreak, currentStreak);
    // Asignar directamente el valor calculado de la racha máxima
    setRachaMaxima(maxStreak);
  }