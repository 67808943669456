export const getlabels = (rangoFechas) => {
  const labels = [];
  const inicio = new Date(rangoFechas.inicio);
  const fin = new Date(rangoFechas.fin);
  
  // Crear una fecha temporal para iterar
  const fechaActual = new Date(inicio);
  
  // Mientras la fecha actual sea menor o igual a la fecha fin
  while (fechaActual <= fin) {
    // Convertir a fecha México
    const fechaMX = new Date(fechaActual.toLocaleString("en-US", { timeZone: "America/Mexico_City" }));
    
    // Formatear la fecha
    const dia = fechaMX.getDate().toString().padStart(2, "0");
    const mes = (fechaMX.getMonth() + 1).toString().padStart(2, "0");
    const año = fechaMX.getFullYear().toString().slice(-2);
    
    // Agregar la fecha formateada al array de labels
    labels.push(`${dia}/${mes}/${año}`);
    
    // Incrementar la fecha actual en un día
    fechaActual.setDate(fechaActual.getDate() + 1);
  }
  
  return labels;
};